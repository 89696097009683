export const TERMS_OF_USE = {
    TITLE: 'Gracenote View Terms of Service',
    TOP_SECTION: [
        'These Gracenote View Terms of Service (“Terms”) govern your use of the Gracenote View platform (“Gracenote View”) made available by Gracenote, Inc. (“Gracenote”) via the Gracenote View online portal.',
        'In order to access Gracenote View, you must register through Gracenote View  and affirmatively agree to these Terms on behalf the individual or entity that you are registering (“Licensee”). By clicking “I agree to the Terms of Service” you represent and warrant that you have read and agreed to these Terms and that you have the legal authority to bind Licensee to these Terms. You agree, on behalf of Licensee, that acceptance of these Terms creates a legal agreement between Gracenote and Licensee (this “Agreement”).',
        'Gracenote may amend or modify the terms of this Agreement in its sole discretion with or without notice. In the event of such amendment or modification, Licensee agrees that any amended or modified terms supersede all prior versions of this Agreement, and that Licensee shall be bound by such amended or modified terms.'
    ],
    SECTIONS: [
        {
            title: 'License Grant.',
            text: 'Subject to Licensee’s compliance with the terms and conditions of this Agreement, and solely for the duration of the Term, Gracenote hereby grants Licensee a limited, non-exclusive, non-assignable, non-transferable, non-sublicensable, revocable license to access and use Gracenote View, and to print, download and export reports containing Gracenote View data, including without limitation TMS IDs, solely for Licensee’s internal business purposes. Licensee may use Gracenote View only as expressly authorized in this paragraph. Any and all other uses of Gracenote View are strictly prohibited. Without limiting the generality of the foregoing, Licensee specifically represents and warrants that Licensee will:',
            list: [
                'only allow Authorized Users (as defined below) to access Gracenote View;',
                'maintain the privacy and confidentiality of Gracenote View;',
                'not, and ensure that Authorized Users do not, publish, broadcast, syndicate, sublicense or sell any materials retrieved from Gracenote View;',
                'not, and ensure that Authorized Users do not, print, download or export any content, material or data from Gracenote View, except for Licensee’s internal use;',
                'not, and ensure that Authorized Users do not, share Gracenote View log-in information, including passwords of Authorized Users, with anyone who is not an Authorized User;',
                'not, and ensure that Authorized Users do not, without our prior written permission, “mirror” any material contained in Gracenote View or any other server;',
                'not, and ensure that Authorized Users do not, embed pages from Gracenote View in “frames” running from other sites;',
                'not, and ensure that Authorized Users do not, use any Gracenote Trademark (as defined below) in any metatag unless granted permission to do so in writing; and',
                'not, and ensure that Authorized Users do not, permit any software program to access Gracenote View for any purpose whatsoever.'
            ]
        },
        {
            title: 'Trademarks.',
            text: 'Gracenote (and Puffy G design), the Gracenote Logos (including PUFFY G (logo)), CDDB, CDDB (and design), MusicID, MediaVOCS, Gravity Mobile, CarStars, MusicID, MusicIP, MusicIP Mixer, DJMIX, DJMOOD, DJSCAN, MYDJ, MusicDNS, and Let Your Music Play are trademarks of Gracenote, Inc., either registered or not (collectively the “Gracenote Trademarks”), within the United States as well as in other countries. Without Gracenote’s prior permission and proper acknowledgement, you agree not to display or use the Gracenote Trademarks in any manner. All other trademarks, service marks, product and service names and company names or logos that appear on the Site are the property of their respective owners.'
        },
        {
            title: 'Access Requirements.',
            text: 'Gracenote View shall be accessed and used only by individual end users to whom Gracenote has provided separate, individual Gracenote View logins and passwords (“Authorized Users”). For purposes of clarification but not limitation, each single login and password shall be used by one (1) natural person.  Licensee hereby agrees to provide Gracenote with immediate notice if it has reason to believe a third party is improperly using or accessing any portion of Gracenote View, and will cooperate with Gracenote to prevent any such future unauthorized and improper use. If the Licensee or Authorized User knows or suspects that any unauthorized users has accessed Gracenote View, they shall immediately notify Gracenote such unauthorized access.'
        },
        {
            title: 'Ownership by Gracenote.',
            text: 'Licensee acknowledges and agrees that Gracenote owns all copyrights and other proprietary rights in and to Gracenote View, including without limitation any content, material and data contained in Gracenote View or included in reports created through use of Gracenote View. Licensee shall not, by virtue of this Agreement or by virtue of its access to Gracenote View, obtain any copyright or other proprietary right or interest in or to Gracenote View except the revocable rights specifically granted to Licensee herein.'
        },
        {
            title: 'Additional Terms and Conditions for Software and EULAs.',
            text: 'Gracenote has prepared certain end-user license agreements (or “EULAs”) for developers and business partners who seek to license Gracenote data and software (“Software”) for their applications or other commercial and non-commercial use. When you download and/or install and use any of Gracenote’s Software, you will be required to agree to one or more EULAs which may include additional terms. You will be bound by any EULA to which you agree. Any Software provided by Gracenote, or made available for download from this server, is the copyrighted work of Gracenote, Inc. and/or its suppliers. Any reproduction or redistribution of any Software not in accordance with the applicable EULA is expressly prohibited by law and may result in severe civil and criminal penalties. In the event of a conflict between such EULAs and these Terms of Use, the respective EULA shall control. For information contact us at gracenotelicensing@gracenote.com.'
        },
        {
            title: 'Termination.',
            text: 'Gracenote may terminate this Agreement, in whole or in part, at any time and for any reason, or for no reason, without notice or liability. In addition, Gracenote may at any time modify, suspend, discontinue or restrict access to Gracenote View for any reason or for no reason, without notice or liability.'
        },
        {
            title: 'Post-Termination.',
            text: 'Upon termination of this Agreement: (i) all rights granted by Gracenote hereunder shall immediately terminate; (ii) Licensee shall immediately cease to access or otherwise use Gracenote View; (iii) Licensee shall cease to use, and shall purge, any content, material and data printed, downloaded or exported from Gracenote View; and (iv) Licensee shall not use for any purpose thereafter any information included in or derived from Gracenote View.'
        },
        {
            title: 'Warranty Disclaimer.',
            text: 'GRACENOTE PROVIDES GRACENOTE VIEW ON AN “AS IS” BASIS, AND MAKES NO EXPRESS OR IMPLIED WARRANTIES REGARDING GRACENOTE VIEW, INCLUDING WITHOUT LIMITATION WITH RESPECT TO ANY COMPONENTS THEREOF OR CONTENT THEREIN. GRACENOTE DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR ANY PARTICULAR PURPOSE. LICENSEE’S USE OF GRACENOTE VIEW IS AT LICENSEE’S OWN RISK.'
        },
        {
            title: 'Limitation of Liability.',
            text: 'IN NO EVENT SHALL GRACENOTE OR ITS AFFILIATES, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES OR AGENTS, BE LIABLE (1) FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES INCLUDING, WITHOUT LIMITATION, DAMAGES RELATED TO LICENSEE’S USE OF GRACENOTE VIEW, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; OR (2) ANY AMOUNT FOR DIRECT DAMAGES IN EXCESS OF $100.'
        },
        {
            title: 'Waiver and Release.',
            text: 'To the maximum extent permitted by applicable law, Licensee hereby waives and releases all debts, demands, causes of action, suits, sum and sums of money, accounts, specialties, covenants, contracts, controversies, agreements, promises, doings, omissions, variances, damages, executions and liabilities, and any and all other claims of every kind, nature and description, against Gracenote, its affiliates, and each of their respective officers, directors, employees, representatives and agents, arising from or relating in any way to Licensee’s use of Gracenote View. Licensee hereby waives the protection of any provision of any law that would operate to preserve claims that are unknown as of the Effective Date, including the benefits of California Civil Code § 1542 and all similar statutes or provisions of law in any jurisdiction. Section 1542 provides:',
            subtext: 'A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if know by him or her must have materially affected his or her settlement with the debtor.'
        },
        {
            title: 'Licensee Indemnity.',
            text: 'Licensee shall indemnify, defend and hold harmless Gracenote, its affiliates, and each of their respective officers, directors, employees, representatives and agents from and against any and all third party claims, damages, costs and expenses (including reasonable out-of-pocket attorneys’ fees) arising out of or relating to: (i) Licensee’s use of Gracenote View; (ii) Licensee’s breach of this Agreement; (iii) any third-party use of Gracenote View resulting from Licensee’s provision of access to Gracenote View, in any manner, to a third-party; (iv) Licensee’s use of Gracenote View in a manner not expressly authorized by this Agreement; and (v) Licensee’s violation of any law, rule or regulation.'
        },
        {
            title: 'Governing Law; Venue.',
            text: 'This Agreement shall be governed by and interpreted under the laws of the State of California, without regard to conflict/choice of law rules or principles. Any suit, action or proceeding arising out of or relating to this Agreement, or Licensee’s use of Gracenote View, shall be brought exclusively in the state or federal courts located in Los Angeles, California. Licensee hereby irrevocably consents to jurisdiction and venue in the state and federal courts located in Los Angeles, California for purposes of any suit, action or proceeding arising out of or relating to this Agreement, or Licensee’s use of Gracenote View.'
        },
        {
            title: 'Non-Waiver.',
            text: 'Neither the failure of Gracenote to insist upon or enforce strict performance by Licensee of any provision of this Agreement, nor the failure, delay or omission by Gracenote in exercising any right with respect to any term of this Agreement, will be construed as a waiver or relinquishment to any extent of Gracenote’s right to assert or rely upon any such provision or right in that or any other instance.'
        },
        {
            title: 'Assignment.',
            text: 'Licensee shall not assign, transfer or otherwise convey to any third party any rights or obligations hereunder except with Gracenote’s prior written consent. Gracenote may freely transfer and assign this Agreement.'
        },
        {
            title: 'Publicity.',
            text: 'Gracenote shall have the right to use the name of Licensee in digital and print publicity, advertising, and sales promotion, including adding Licensee’s name to public lists of Gracenote clients, or including Licensee’s name in a press release announcing the parties’ agreement, as may be prepared and published by Gracenote in its sole discretion.'
        },
        {
            title: 'Survival.',
            text: 'Sections 3 and 5-15 shall survive any termination or expiration of this Agreement.'
        }
    ]
}