import React from 'react';
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import './GNDropdown.scss';
import ClassNames from 'classnames';

export const GNDropdown = (props) => (
    <div className='gndropdown-main-container'>
        <div className='gndropdown-label'>{props?.label}</div>
        <SelectSearch
            className={props?.className}
            onChange={(item) => props?.handleChange(item)}
            options={props?.options}
            disabled={props?.disabled}
            emptyMessage={props?.emptyMessage}
            // Initial value for GNDropdown, only will update with onChange
            value={props?.value}
            search={true}
            getOptions={(inputValue, options) => {
                if (inputValue) {
                    return options.filter((item) =>
                        typeof item?.name === 'string'
                            ? item.name.toLowerCase().includes(inputValue.toLowerCase())
                            : item.value.toLowerCase().includes(inputValue.toLowerCase())
                    );
                } else {
                    return options;
                }
            }}
            {...(props.renderValue && { renderValue: props.renderValue })}
            {...(props.placeholder && { placeholder: props.placeholder })}
        />
        <i className={ClassNames('fas fa-angle-down', { 'has-label': props?.label })} role='icon' />
        {props.isRequired && <div className='required-text' role='required-text'>*Required</div>}
    </div>
);

GNDropdown.defaultProps = {
    className: 'select-search',
    disabled: false,
    emptyMessage: 'Not Found',
    isRequired: false
};

GNDropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    emptyMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    renderValue: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default GNDropdown;
