import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ROUTES } from '../../config/Routes';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { gnviewClearState } from '../../actions/GNViewAuthActions';
import "./Logout.scss";
import PropTypes from 'prop-types';

export class Logout extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        localStorage.clear();
        this.props.gnviewClearState()
        window.location.replace(ROUTES.LANDING);
    }

    render() {
        return (
            <div className="gnview-logout-content">
                <LoadingSpinner />
            </div>
        )
    }
}

Logout.propTypes = {
    gnviewClearState: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ gnviewClearState }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);