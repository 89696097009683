import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gvauthSelEntitlements, gvauthSelEmail, gnviewSelAccountModalShow, gnviewSelAccountModalSelectedTab, gvauthSelMobiusSourceID, gvauthSelAccountType } from '../../reducers/GNVAuthReducer';
import GNModal from '../gnModal/GNModal';
import {
    ACCOUNT_SETTINGS_TAB_OPTIONS,
    CONTACT_SUPPORT_MESSAGE,
    formatGNIDPlanFields,
    formatGNViewPlanFields,
    formatProfileFields,
    GNID_PLAN_DETAILS_LABEL,
    GNID_PLAN_FIELDS,
    GNVIEW_PLAN_DETAILS_LABEL,
    GNVIEW_PLAN_FIELDS, hasMobiusEntitlement,
    PROFILE_FIELDS,
    PROFILE_LABEL,
    USER_SETTINGS_BUTTON_TEXT,
    USER_SETTINGS_CONFIRMATIONS
} from '../../constants/AccountSettings';
import './AccountSettingsModal.scss';
import GNListGroup from '../gnListGroup/GNListGroup';
import ClassNames from 'classnames';
import { gnviewGetStreamingVideoCatalogs } from '../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import UserSettings from './userSettings/UserSettings';
import GNConfirmationModal from '../gnConfirmationModal/GNConfirmationModal';
import { gnviewToggleAccountModalShow, gnviewSetAccountModalTab, mobiusGetEntitlementLanguages } from '../../actions/GNViewAuthActions';
import { capitalizeFirstLetter } from '../../utils/GeneralUtils';

export const AccountSettingsModal = () => {
    const dispatch = useDispatch();
    const entitlements = useSelector(gvauthSelEntitlements);
    const email = useSelector(gvauthSelEmail);
    const accountType = capitalizeFirstLetter(useSelector(gvauthSelAccountType)) || null;
    const selectedTab = useSelector(gnviewSelAccountModalSelectedTab);
    const showModal = useSelector(gnviewSelAccountModalShow);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const [entitledCatalogs, setEntitledCatalogs] = useState([]);
    const [catalogsIsLoading, setCatalogsIsLoading] = useState(true);
    // Cancel confirmation modal
    const [touched, setTouched] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    useEffect(() => {
        dispatch(gnviewGetStreamingVideoCatalogs()).then((response) => {
            setEntitledCatalogs(response.result);
            setCatalogsIsLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewGetStreamingVideoCatalogs error: ${error.message}`, error));
            setCatalogsIsLoading(false);
        });
        dispatch(mobiusGetEntitlementLanguages(sourceId)).catch((error) => {
            dispatch(gnviewSendLogMessage(`mobiusGetEntitlementLanguages error: ${error.message}`, error));
        });
    }, [sourceId, dispatch]);


    const handleModalClose = () => {
        if (touched) {
            setShowConfirmCancelModal(true);
        } else {
            dispatch(gnviewToggleAccountModalShow());
            // Reset touched and tab to default state
            dispatch(gnviewSetAccountModalTab(ACCOUNT_SETTINGS_TAB_OPTIONS.ACCOUNT_INFO));
            setTouched(false);
        }
    };

    const formatEntitlementsCatalogs = (entitlementsObj) => ({
        ...entitlementsObj,
        streamingVideos: {
            exclude: entitlementsObj?.streamingVideos?.exclude,
            include: {
                catalogs: entitledCatalogs.map(cat => cat.name)
            }
        }
    });

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal className={ClassNames('gnview-account-settings-modal', {'hide': showConfirmCancelModal})} show={showModal} onHide={() => handleModalClose()}>
                {!catalogsIsLoading && <div className='account-settings-modal-main-container'>
                    <div className='left-nav-menu-container'>
                        {Object.values(ACCOUNT_SETTINGS_TAB_OPTIONS).map((item, idx) => <div className={ClassNames('left-nav-menu-item', {'selected': selectedTab === item})} role='button' key={idx} onClick={() => dispatch(gnviewSetAccountModalTab(item))}>{item}</div>)}
                    </div>
                    {selectedTab === ACCOUNT_SETTINGS_TAB_OPTIONS.ACCOUNT_INFO && <div className='account-info-container'>
                        <div className='profile-section'>
                            <div className='account-settings-modal-header'>{PROFILE_LABEL}</div>
                            <div className='profile-details-container'>
                                <GNListGroup fields={PROFILE_FIELDS} data={formatProfileFields(email, entitlements, accountType)} renderListItem={(item, idx) => <div className='account-info-item' key={idx}>{item}</div>}/>
                            </div>
                        </div>
                        <div className='plan-details-section gnview'>
                            <div className='account-settings-modal-header'>{GNVIEW_PLAN_DETAILS_LABEL}</div>
                            <GNListGroup fields={GNVIEW_PLAN_FIELDS} data={formatGNViewPlanFields(formatEntitlementsCatalogs(entitlements))} renderListItem={(item, idx) => <div className='account-info-item' key={idx}>{item}</div>}/>
                        </div>
                        {hasMobiusEntitlement(entitlements) && <div className='plan-details-section gnid'>
                            <div className='account-settings-modal-header'>{GNID_PLAN_DETAILS_LABEL}</div>
                            <GNListGroup fields={GNID_PLAN_FIELDS} data={formatGNIDPlanFields(entitlements)} renderListItem={(item, idx) => <div className='account-info-item' key={idx}>{item}</div>}/>
                        </div>}
                    </div>}
                    {selectedTab === ACCOUNT_SETTINGS_TAB_OPTIONS.GRACENOTE_VIEW_SETTINGS && <UserSettings setTouched={setTouched}/>}
                    {selectedTab === ACCOUNT_SETTINGS_TAB_OPTIONS.SUPPORT && <div className='support-container'>{CONTACT_SUPPORT_MESSAGE}</div>}
                </div>}
            </GNModal>
            <GNConfirmationModal
                cancelButtonHandler={() => setShowConfirmCancelModal(false)}
                cancelButtonText={USER_SETTINGS_BUTTON_TEXT.CANCEL}
                message={USER_SETTINGS_CONFIRMATIONS.CANCEL_CONFIRMATION_MESSAGE}
                show={showConfirmCancelModal}
                submitButtonHandler={() => {
                    setShowConfirmCancelModal(false);
                    dispatch(gnviewToggleAccountModalShow());
                    setTouched(false);
                }}
                submitButtonText={USER_SETTINGS_BUTTON_TEXT.EXIT}
                title={USER_SETTINGS_CONFIRMATIONS.CANCEL_CONFIRMATION_TITLE} />
        </ErrorBoundary>
    )
};

export default AccountSettingsModal;