import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { GNVAuthReducer, GNVAUTH_STORE_NAME } from './GNVAuthReducer';

const createRootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        [GNVAUTH_STORE_NAME]: GNVAuthReducer
    })
};

export default createRootReducer;