import React from 'react';
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import './GDSDropdown.scss';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GDSDropdown = (props) => (
    <div className={ClassNames('gds-dropdown-container', {'large-size-dropdown': props.isLarge})}>
        <div className='gds-dropdown-label' role='label'>
            {props?.label}
            {props.isRequired && <FontAwesomeIcon icon="circle"/>}
        </div>
        <SelectSearch
            className={props?.className}
            onChange={(item) => props?.handleChange(item)}
            options={props?.options}
            disabled={props?.disabled}
            emptyMessage={props?.emptyMessage}
            // Initial value for GDSDropdown, only will update with onChange
            value={props?.value}
            search={true}
            getOptions={(inputValue, options) => {
                if (inputValue) {
                    return options.filter((item) =>
                        typeof item?.name === 'string'
                            ? item.name.toLowerCase().includes(inputValue.toLowerCase())
                            : item.value.toLowerCase().includes(inputValue.toLowerCase())
                    );
                } else {
                    return options;
                }
            }}
            // disable Last Pass icon
            data-lpignore={true}
            {...(props.renderValue && { renderValue: props.renderValue })}
            {...(props.placeholder && { placeholder: props.placeholder })}
        />
        <i className={ClassNames('fas fa-angle-down', { 'has-label': props?.label })} />
    </div>
);

GDSDropdown.defaultProps = {
    className: 'select-search',
    disabled: false,
    emptyMessage: 'Not Found',
    isLarge: false,
    isRequired: false,
    showError: false,
    handleChange: () => {},
    value: null,
    options: []
};

GDSDropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    emptyMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    isLarge: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    renderValue: PropTypes.func,
    showError: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default GDSDropdown;
