import React from 'react';
import './LoadingSpinner.scss';

export const LoadingSpinner = () => {
    return (
        <div className={'spinner-container'}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'
            }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(77,50)">
                    <g transform="rotate(0)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="1" transform="scale(1.21371 1.21371)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.875s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.875s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(69.09188309203678,69.09188309203678)">
                    <g transform="rotate(45)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.875" transform="scale(1.27621 1.27621)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.75s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.75s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(50,77)">
                    <g transform="rotate(90)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.75" transform="scale(1.33871 1.33871)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.625s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.625s"></animate>
                        </circle>
                    </g>
                </g><g transform="translate(30.90811690796322,69.09188309203678)">
                    <g transform="rotate(135)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.625" transform="scale(1.40121 1.40121)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s"></animate>
                        </circle>
                    </g>
                </g><g transform="translate(23,50)">
                    <g transform="rotate(180)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.5" transform="scale(1.46371 1.46371)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.375s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.375s"></animate>
                        </circle>
                    </g>
                </g><g transform="translate(30.908116907963212,30.90811690796322)">
                    <g transform="rotate(225)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.375" transform="scale(1.02621 1.02621)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.25s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.25s"></animate>
                        </circle>
                    </g>
                </g><g transform="translate(49.99999999999999,23)">
                    <g transform="rotate(270)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.25" transform="scale(1.08871 1.08871)">
                            <animateTransform attributeName="transform" type="scale" begin="-0.125s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.125s"></animate>
                        </circle>
                    </g>
                </g><g transform="translate(69.09188309203678,30.908116907963212)">
                    <g transform="rotate(315)">
                        <circle cx="0" cy="0" r="6" fill="#cccccc" fillOpacity="0.125" transform="scale(1.15121 1.15121)">
                            <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s"></animate>
                        </circle>
                    </g>
                </g></svg>
        </div>);
}
export default LoadingSpinner;