import chmln from '@chamaeleonidae/chmln';
import { useSelector } from 'react-redux';
import { gvauthUserDbId, gvauthSelCompany, gvauthSelEmail, gvauthSelGroup, gvauthSelRole, gvauthSelTeam } from '../../reducers/GNVAuthReducer';

// Chameleon and Heap integrations
export const Integration = () => {
    const company = useSelector(gvauthSelCompany);
    const email = useSelector(gvauthSelEmail);
    const group = useSelector(gvauthSelGroup);
    const role = useSelector(gvauthSelRole);
    const team = useSelector(gvauthSelTeam);
    const userDbId = useSelector(gvauthUserDbId);
    const nielsenGroup = "GNVIEW";

    if (!email) {
        chmln.identify(`gnview-landing`, {
            nielsen_group: nielsenGroup
        });
    } else {
        const groupCreatedDate = group?.createdDate || null;
        const groupEndDate = group?.endDate || null;
        const groupName = group?.name || null;
        const groupIsTrial = group?.isTrial || false;
        const accountType = group?.accountType || null;
        chmln.identify(email, {
            accountType,
            company: {
                name: company,
                uid: company
            },
            email,
            is_trial: groupIsTrial,
            nielsen_group: nielsenGroup,
            group_created_date: groupCreatedDate,
            group_end_date: groupEndDate,
            group_name: groupName,
            role,
            team
        });
        window.heap.identify(email);
        if (email) {
            window.heap.addUserProperties({
                accountType,
                company,
                role,
                team,
                userDbId
            })
        }
    }

    return null;
};

export default Integration;
