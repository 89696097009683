import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import "./GNNotification.scss";
import PropTypes from 'prop-types';

export const GNNotification = ({handleShow, message, milliseconds, show, success}) => {
    useEffect(() => {
        if (milliseconds) {
            setTimeout(() => {
                handleShow(false);
            }, milliseconds);
        }
    }, [handleShow, milliseconds]);

    return (
        <Alert show={show} variant="dark" className="gnview-notification">
            <div className="success-error-icon-container" role='icon'>
                {success && <FontAwesomeIcon icon="check" className="success-icon"/>}
                {!success && <FontAwesomeIcon icon="exclamation-triangle" className="error-icon"/>}
            </div>
            <div className="message">
                {message}
            </div>
            <FontAwesomeIcon icon="times" className="close-icon" onClick={() => handleShow(false)}/>
        </Alert>
    )
}

GNNotification.defaultProps = {
    success: true
};

GNNotification.propTypes = {
    handleShow: PropTypes.func,
    message: PropTypes.string,
    milliseconds: PropTypes.number,
    show: PropTypes.bool,
    success: PropTypes.bool
};

export default GNNotification;