import React from 'react';
import "./TransferThankYou.scss";
import {
    TRANSFER_USER_THANK_YOU_HEADER,
    TRANSFER_USER_THANK_YOU_MESSAGE,
    TRANSFER_USER_SUBMISSION_ERROR_TEXT,
    TRANSFER_USER_SUBMISSION_ERROR_LINK,
    TRANSFER_USER_THANK_YOU_MESSAGE_SUCCESS_SUPPORT,
    TRANSFER_USER_THANK_YOU_MESSAGE_ERROR_SUPPORT,
    TRANSFER_USER_SUPPORT_EMAIL
} from '../../../constants/AdminPanel';
import corgiErrorPhoto from '../../../images/sadcorgi.png';
import PropTypes from 'prop-types';

export const TransferThankYou = ({ setStep, transferUserSuccess }) => {
    const handleSupportEmail = (e) => {
        window.location.href = `mailto:${TRANSFER_USER_SUPPORT_EMAIL}`;
        e.preventDefault();
    };

    return (
        <>
            {transferUserSuccess && (
                <div className="thank-you-content">
                    <div className="thank-you-header">{TRANSFER_USER_THANK_YOU_HEADER}</div>
                    <div className="thank-you-message">{TRANSFER_USER_THANK_YOU_MESSAGE}</div>
                    <div className="thank-you-message-support">
                        {TRANSFER_USER_THANK_YOU_MESSAGE_SUCCESS_SUPPORT}
                        <span
                            className="transfer-form-link"
                            onClick={(e) => handleSupportEmail(e)}
                        >
                            {TRANSFER_USER_SUPPORT_EMAIL}
                        </span>
                    </div>
                </div>
            )}
            {!transferUserSuccess && (
                <div className="thank-you-content">
                    <img src={corgiErrorPhoto} />
                    <div className={"thank-you-message-error"}>
                        {TRANSFER_USER_SUBMISSION_ERROR_TEXT}
                        <span
                            className="transfer-form-link"
                            onClick={() => setStep(1)}
                        >
                            {TRANSFER_USER_SUBMISSION_ERROR_LINK}
                        </span>
                    </div>
                    <div className="thank-you-message-support">
                        {TRANSFER_USER_THANK_YOU_MESSAGE_ERROR_SUPPORT}
                    </div>
                    <div className="thank-you-email-support">
                        <span
                            className="transfer-form-link"
                            onClick={(e) => handleSupportEmail(e)}
                        >
                            {TRANSFER_USER_SUPPORT_EMAIL}
                        </span>
                    </div>
                </div>
            )}
        </>
    )
};

TransferThankYou.propTypes = {
    setStep: PropTypes.func,
    transferUserSuccess: PropTypes.bool
};

export default TransferThankYou;