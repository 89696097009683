import React from 'react';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import "./TermsOfUse.scss";
import { TERMS_OF_USE } from '../../constants/TermsOfUse';
import gnViewLogo from '../../images/gracenote_logo_caps.png';

export const TermsOfUse = () => {
    return (
        <ErrorBoundary>
            <div className="gnview-terms-of-use-container">
                <img src={gnViewLogo} className='gnview-logo' alt='Gracenote View Logo'/>
                <div className="title">{TERMS_OF_USE.TITLE}</div>
                {TERMS_OF_USE.TOP_SECTION.map((topSection, idx) => <div className="top-secton" key={idx}>{topSection}</div>)}
                {TERMS_OF_USE.SECTIONS.map((section) => (
                    <ul className="section" key={section.title}>
                        <li>
                            <span className="section-title">{section.title}</span>
                            <span>{section.text}</span>
                            {section?.list?.length > 0 && <ul>
                                {section.list.map((listText, idx) => (<li key={idx}>{listText}</li>))}
                            </ul>}
                            {section?.subtext && <div className="section-subtext">{section.subtext}</div>}
                        </li>
                    </ul>
                ))}
            </div>
        </ErrorBoundary>
    )
}

export default TermsOfUse;