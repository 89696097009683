import { LEARN_MORE_LINK } from './ContactUs';
import { ROUTES } from '../config/Routes';
import programDetailsPhoto from '../images/program_details.png';
import programAiringsPhoto from '../images/program_airings.png';
import schedulePhoto from '../images/schedule.png';
import availabilityPhoto from '../images/availability.png';

export const LEARN_WHAT_YOU_CAN_DO = 'Learn what you can do with Gracenote View';
export const WHAT_YOU_GET_HEADER = 'What you get with Gracenote View';
export const WHAT_YOU_GET_DESC = 'One tool to manage all your content operation needs for exploring, tracking, and monetizing the content ecosystem.';

export const LANDING_CONTENT_HEADER = {
    title: 'Lead the way with the largest real-time global content library at your fingertips.',
    subtitle: [
        {
            header: '50+',
            subheader: 'countries'
        },
        {
            header: '100+',
            subheader: 'languages'
        },
        {
            header: '200+',
            subheader: 'streaming catalogs'
        },
        {
            header: '70K+',
            subheader: 'networks'
        },
        {
            header: '30M+',
            subheader: 'programs'
        }
    ]
};

export const LANDING_CONTENT_BODY = {
    explore: {
        header: 'Explore',
        subheader: 'Provide the best in-class entertainment experience',
        bullets: [
            'Create new UI experiences',
            'Promote content in various ways',
            'Evaluate competitor programming'
        ]
    },
    track: {
        header: 'Track',
        subheader: 'Ensure superior quality on all your distribution channels',
        bullets: [
            'Verify your programs have appropriate imagery and descriptions',
            'Track schedules to maintain proper line-up data for your programs',
            'Validate deep links to enable proper search & discovery'
        ]
    },
    account: {
        header: 'Account',
        subheader: 'Confirm airings and proper monetization',
        bullets: [
            'Reconcile content airings and streaming royalties',
            'Audit for performance royalties',
            'Manage paid programming buys'
        ]
    }
};

export const WHAT_YOU_GET_TYPES = {
    PROGRAM_DETAILS: 'Program Details',
    PROGRAM_AIRINGS: 'Program Airings',
    SCHEDULE: 'Schedule',
    PROGRAM_AVAILABILITY: 'Program Availability'
};

export const WHAT_YOU_GET_IMAGES = {
    [WHAT_YOU_GET_TYPES.PROGRAM_DETAILS]: {
        img: programDetailsPhoto,
        description: 'Look up and download Gracenote Video Data: program details, IDs, descriptive data, and imagery'
    },
    [WHAT_YOU_GET_TYPES.PROGRAM_AIRINGS]: {
        img: programAiringsPhoto,
        description: 'Airing details for a specified set of programs across all global TV'
    },
    [WHAT_YOU_GET_TYPES.SCHEDULE]: {
        img: schedulePhoto,
        description: 'Linear TV schedule for a specified channel, country or market area'
    },
    [WHAT_YOU_GET_TYPES.PROGRAM_AVAILABILITY]: {
        img: availabilityPhoto,
        description: 'Deep links to programs across various streaming services'
    }
};

export const LANDING_CONTACT_SALES = {
    buttonText: 'FAST Program Trial',
    freeTrial: 'Free Trial for Data Licensees',
    url: LEARN_MORE_LINK
};

export function getCopyRightText() {
    return `© ${new Date().getFullYear()} Gracenote View`;
}

export const PRIVACY_POLICY = {
    text: "Privacy Policy",
    url: "https://www.nielsen.com/legal/gracenote-services-privacy-statement/"
};

export const TERMS_OF_USE_TEXT = "Terms of Use";

// Only used on homepage/landing
export const LANDING_FOOTER_LINKS = [
    {
        title: 'Contact Support',
        path: LEARN_MORE_LINK
    },
    {
        title: PRIVACY_POLICY.text,
        path: PRIVACY_POLICY.url
    },
    {
        title: TERMS_OF_USE_TEXT,
        path: `${window.location.origin}${ROUTES.TERMS_OF_USE}`
    }
];