// TODO: Ideally these (GNVIEW_AUTH, GNVIEW_SETTINGS, GNVIEW_EMAIL, GNVIEW_VERSION) will in a common repo will fix in https://jira.gracenote.com/browse/VIEW-1769
// Auth and user settings
export const OLD_GNVIEW_AUTH = 'gnview-auth';
export const GNVIEW_AUTH_VERSION = 1;
export const GNVIEW_AUTH = `gnview-auth-v${GNVIEW_AUTH_VERSION}`; // TODO
export const GNVIEW_SETTINGS = 'gnview-user-settings'; // TODO
// Cookies
export const GNVIEW_EMAIL = 'gnview-email'; // TODO
export const GNVIEW_VERSION = 'gnview-version'; // TODO

// Redux
export const GNVIEW_MENU_STATE_VERSION = 1;
export const GNVIEW_MENU_STATE = `gnview-menu-state-v${GNVIEW_MENU_STATE_VERSION}`;
