import prom from "promjs";
import axios from "axios";
import EnvironmentService from "../services/EnvironmentService";

const registry = prom();

const NAMESPACE = 'gnview_webclient'

const flushMetrics = () => {
    const isInDevMode = new EnvironmentService().getIsInDevMode();
    if (!isInDevMode) {
        // trailing slash in /metrics/ is required by weaveworks/prom-aggregation-gateway
        axios.post('/metrics/', registry.metrics());
    }
};

const loginCounter = registry.create('counter', `${NAMESPACE}_logins_v2`, 'A counter for logins');

export const incLoginCounter = (labels) => {
    loginCounter.inc(labels);
    flushMetrics()
};
