import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import get from 'lodash.get';
import ProgressBar from 'react-bootstrap/ProgressBar';
import "./TrialForm.scss";
import { ROUTES } from '../../config/Routes';
import gnViewLogo from '../../images/GN_View_logo.png';
import GDSCard from '../gds/gdsCard/GDSCard';
import GDSInput from '../gds/gdsInput/GDSInput';
import GDSDropdown from '../gds/gdsDropdown/GDSDropdown';
import loadingDoggo from '../../images/LoadingDoggo.gif';
import { Button, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { getCopyRightText, LANDING_CONTACT_SALES, LANDING_FOOTER_LINKS } from '../../constants/Landing';
import {
    TRIAL_FORM_HEADER_TEXT,
    TRIAL_FORM_USE_COMPANY_EMAIL_TEXT,
    TRIAL_FORM_INITIAL_VALUES,
    TRIAL_FORM_INITIAL_TOUCHED,
    TRIAL_FORM_VALIDATION_SCHEMA,
    TRIAL_FORM_FIELDS,
    TRIAL_FORM_ROLES,
    TRIAL_FORM_OTHER_VALUE,
    TRIAL_FORM_SUBMIT_BUTTON_TEXT,
    INVALID_EMAIL_DOMAINS,
    TRIAL_FORM_NOT_CUSTOMER,
    TRIAL_FORM_INVALID_EMAIL,
    TRIAL_FORM_AGREE_TO_TERMS,
    TRIAL_FORM_ALL_FIELDS_REQUIRED,
    TRIAL_FORM_TEAMS,
    TRIAL_FORM_LABELS,
    TRIAL_FORM_LOADING_MESSAGE
} from '../../constants/TrialFormValidation';
import { getCountryOptions } from '../../utils/GeneralUtils';
import { useDispatch } from 'react-redux';
import {
    gnviewGetTrialEmailDomains,
    gnviewGetTrialCompanies,
    gnviewCreateTrialEntitlementGroup,
    gnviewDeleteTrialEntitlementGroup,
    gnviewUpdateTrialEntitlementExpression,
    gnviewCreateSalesforceContact,
    gnviewCreateTrialCognitoUser
} from '../../actions/GNViewActions';
import { gnviewSetTrialSignupValues, gnviewSetTrialSignupSuccess } from '../../actions/GNViewAuthActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import _debounce from 'lodash.debounce';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gnviewSelTrialSignupValues } from '../../reducers/GNVAuthReducer';
import _isEmpty from 'lodash.isempty';

export const TrialForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const signupValues = useSelector(gnviewSelTrialSignupValues);
    const [isLoading, setIsLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [emailDomains, setEmailDomains] = useState(new Set());
    const [companies, setCompanies] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [progressNow, setProgressNow] = useState(20);

    useEffect(() => {
        dispatch(gnviewGetTrialEmailDomains())
            .then((response) => {
                // eslint-disable-next-line babel/camelcase
                response.forEach(({ email_domain }) =>
                    setEmailDomains((prev) => new Set(prev.add(email_domain)))
                );
            })
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnviewGetTrialEmailDomains error: ${error.message}`, error));
                setIsLoading(false);
            });
        dispatch(gnviewGetTrialCompanies())
            .then((response) => {
                setCompanies(response.map(item => ({ name: item.name, value: item.id})).concat(TRIAL_FORM_OTHER_VALUE));
                setIsLoading(false);
            })
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnviewGetTrialCompanies error: ${error.message}`, error));
                setIsLoading(false);
            });
        if (!_isEmpty(signupValues)) {
            setBtnDisabled(false);
        }
    }, [dispatch, signupValues]);

    const setEmailDirty = (isBtnDisabled, topErrorMessage) => {
        setBtnDisabled(isBtnDisabled);
        setEmailErrorMessage(topErrorMessage);
    };

    const verifyEmailAddress = (inputEmail) => {
        const inputDomain = inputEmail.split('@')?.[1] || '';
        if (inputDomain.length > 0 && INVALID_EMAIL_DOMAINS.includes(inputDomain.split('.')?.[0])) {
            // Personal Email Domain
            setEmailDirty(true, TRIAL_FORM_INVALID_EMAIL);
        } else if (inputDomain.length > 0 && emailDomains.size > 1 && !emailDomains.has(inputDomain)) {
            // Company Domain
            setEmailDirty(true, TRIAL_FORM_NOT_CUSTOMER);
        } else {
            setEmailDirty(false, '');
        }
    };

    const debounceEmailAddress = _debounce((inputEmail) => {
        verifyEmailAddress(inputEmail);
    }, 700);

    const handleFormSubmit = (values) => {
        setIsLoading(true);
        setFormSubmitting(true);
        dispatch(gnviewCreateTrialEntitlementGroup(values)).then((response) => {
            updateEntitlementExpression(response?.result?.id, values);
        })
            .catch((err) => {
                dispatch(gnviewSetTrialSignupSuccess(false));
                dispatch(gnviewSetTrialSignupValues(values));
                dispatch(gnviewSendLogMessage(`gnviewCreateTrialEntitlementGroup error: ${err.message}`, err));
                history.push(ROUTES.THANK_YOU);
                setIsLoading(false);
            });
    };

    const updateEntitlementExpression = (id, values) => {
        setProgressNow(40);
        dispatch(gnviewUpdateTrialEntitlementExpression(id, values)).then(() => {
            createCognitoUser(id, values);
        })
            .catch((err) => {
                dispatch(gnviewSetTrialSignupSuccess(false));
                dispatch(gnviewSendLogMessage(`gnviewUpdateTrialEntitlementExpression error: ${err.message}`, err));
                history.push(ROUTES.THANK_YOU);
                setIsLoading(false);
            });
    };

    const createCognitoUser = (groupId, values) => {
        setProgressNow(65);
        dispatch(gnviewCreateTrialCognitoUser(values)).then(() => {
            createSalesforceContact(values);
        })
            .catch((err) => {
                dispatch(gnviewDeleteTrialEntitlementGroup(groupId));
                dispatch(gnviewSetTrialSignupSuccess(false));
                dispatch(gnviewSendLogMessage(`gnviewCreateTrialCognitoUser error: ${err.message}`, err));
                history.push(ROUTES.THANK_YOU);
                setIsLoading(false);
            });
    };

    const createSalesforceContact = (values) => {
        setProgressNow(90);
        const body = {
            email: values[TRIAL_FORM_FIELDS.EMAIL_ADDRESS],
            firstName: values[TRIAL_FORM_FIELDS.FIRST_NAME],
            lastName: values[TRIAL_FORM_FIELDS.LAST_NAME],
            companyName: values[TRIAL_FORM_FIELDS.COMPANY] === TRIAL_FORM_OTHER_VALUE.value ? values[TRIAL_FORM_FIELDS.COMPANY_OTHER] : values[TRIAL_FORM_FIELDS.COMPANY],
            salesforceAccountId: values[TRIAL_FORM_FIELDS.COMPANY_ID],
            countryCoverage: values.countryCoverage,
            accountType: 'trial'
        };
        dispatch(gnviewCreateSalesforceContact(body)).then(() => {
            dispatch(gnviewSetTrialSignupSuccess(true));
            history.push(ROUTES.THANK_YOU);
            setIsLoading(false);
        })
            .catch((err) => {
                // We set signup success to true here because the API will send an email to Natasha if anything goes wrong. No further action required from the user.
                dispatch(gnviewSetTrialSignupSuccess(true));
                dispatch(gnviewSetTrialSignupValues(values));
                dispatch(gnviewSendLogMessage(`gnviewCreateSalesforceContact error: ${err.message}`, err));
                history.push(ROUTES.THANK_YOU);
                setIsLoading(false);
            });
    };

    return (
        <div className="gnview-trial-form-container">
            <div className='trial-form-header'>
                <img src={gnViewLogo} className='trial-form-gnlogo' alt='Gracenote View Logo' />
                <div className='buttons-container'>
                    <a href={LANDING_CONTACT_SALES.url} className='contact-sales-button'>
                        <Button variant='bright-blue' className='request-demo-button'>{LANDING_CONTACT_SALES.buttonText}</Button>
                    </a>
                    <div className='left-border'></div>
                    <Link to={ROUTES.LOGIN} className='login-link'>
                        <Button className='login-button'>Login</Button>
                    </Link>
                </div>
            </div>
            {isLoading ? (
                <>
                    {formSubmitting ?
                        <div className="loading-container">
                            <img src={loadingDoggo} alt='Gracenote Loading Dog Gif' />
                            <div className="loading-message">{TRIAL_FORM_LOADING_MESSAGE}</div>
                            <div className="progress-bar-container">
                                <ProgressBar animated min={10} max={95} now={progressNow} />
                            </div>
                        </div>
                        : <LoadingSpinner />
                    }
                </>
            ) : (
                <>
                    <div className="gnview-trial-form-content">
                        <Formik
                            initialValues={TRIAL_FORM_INITIAL_VALUES}
                            initialTouched={TRIAL_FORM_INITIAL_TOUCHED}
                            validationSchema={TRIAL_FORM_VALIDATION_SCHEMA}
                            onSubmit={(values) => handleFormSubmit(values)}>
                            {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => {
                                const validateForm = (formName) =>
                                    isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                                const handleFormChange = (formName, value) => {
                                    if (formName === TRIAL_FORM_FIELDS.COMPANY_ID) {
                                        const companyName = companies.find(company => company.value === value)?.name;
                                        setFieldValue('company', companyName);
                                        values[TRIAL_FORM_FIELDS.COMPANY] = companyName;
                                    }
                                    if (!touched[formName]) {
                                        setFieldTouched(formName, true);
                                    }
                                    setFieldValue(formName, value);
                                };

                                const handleEmailOnChange = async (item) => {
                                    const inputEmail = item.trim().toLowerCase();
                                    handleFormChange(TRIAL_FORM_FIELDS.EMAIL_ADDRESS, inputEmail);
                                    debounceEmailAddress(inputEmail);
                                };

                                if (!_isEmpty(signupValues)) {
                                    Object.entries(signupValues).forEach(([key, val]) => {
                                        handleFormChange(key, val);
                                        delete signupValues[key];
                                    })
                                }

                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <GDSCard>
                                            <div className="trial-form-title">{TRIAL_FORM_HEADER_TEXT}</div>
                                            <GDSInput
                                                handleChange={(item) => handleEmailOnChange(item)}
                                                helpText={TRIAL_FORM_USE_COMPANY_EMAIL_TEXT}
                                                isRequired={true}
                                                label={TRIAL_FORM_LABELS.EMAIL_ADDRESS}
                                                errorMessage={emailErrorMessage}
                                                validateInput={() => validateForm(TRIAL_FORM_FIELDS.EMAIL_ADDRESS)}
                                                validationText={errors[TRIAL_FORM_FIELDS.EMAIL_ADDRESS]}
                                                value={values[TRIAL_FORM_FIELDS.EMAIL_ADDRESS]}
                                            />
                                            <GDSInput
                                                handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.FIRST_NAME, item)}
                                                isRequired={true}
                                                label={TRIAL_FORM_LABELS.FIRST_NAME}
                                                validateInput={() => validateForm(TRIAL_FORM_FIELDS.FIRST_NAME)}
                                                validationText={errors[TRIAL_FORM_FIELDS.FIRST_NAME]}
                                                value={values[TRIAL_FORM_FIELDS.FIRST_NAME]}
                                            />
                                            <GDSInput
                                                handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.LAST_NAME, item)}
                                                isRequired={true}
                                                label={TRIAL_FORM_LABELS.LAST_NAME}
                                                validateInput={() => validateForm(TRIAL_FORM_FIELDS.LAST_NAME)}
                                                validationText={errors[TRIAL_FORM_FIELDS.LAST_NAME]}
                                                value={values[TRIAL_FORM_FIELDS.LAST_NAME]}
                                            />
                                            <div>
                                                <GDSDropdown
                                                    handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.COMPANY_ID, item)}
                                                    isRequired={true}
                                                    label={TRIAL_FORM_LABELS.COMPANY}
                                                    options={companies}
                                                    validationText={errors[TRIAL_FORM_FIELDS.COMPANY_ID]}
                                                    value={values[TRIAL_FORM_FIELDS.COMPANY_ID]}
                                                />
                                                {values[TRIAL_FORM_FIELDS.COMPANY_ID] === TRIAL_FORM_OTHER_VALUE.value && (
                                                    <GDSInput
                                                        className="trial-form-company-other"
                                                        handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.COMPANY_OTHER, item)}
                                                        isRequired={true}
                                                        label={TRIAL_FORM_LABELS.ANSWER_OTHER_PLEASE_SPECIFY}
                                                        validateInput={() => validateForm(TRIAL_FORM_FIELDS.COMPANY_OTHER)}
                                                        validationText={errors[TRIAL_FORM_FIELDS.COMPANY_OTHER]}
                                                        value={values[TRIAL_FORM_FIELDS.COMPANY_OTHER]}
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                <GDSDropdown
                                                    handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.ROLE, item)}
                                                    isRequired={true}
                                                    label={TRIAL_FORM_LABELS.ROLE}
                                                    options={TRIAL_FORM_ROLES}
                                                    validationText={errors[TRIAL_FORM_FIELDS.ROLE]}
                                                    value={values[TRIAL_FORM_FIELDS.ROLE]}
                                                />
                                                {values[TRIAL_FORM_FIELDS.ROLE] === TRIAL_FORM_OTHER_VALUE.value && (
                                                    <GDSInput
                                                        className="trial-form-role-other"
                                                        handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.ROLE_OTHER, item)}
                                                        isRequired={true}
                                                        label={TRIAL_FORM_LABELS.ANSWER_OTHER_PLEASE_SPECIFY}
                                                        validateInput={() => validateForm(TRIAL_FORM_FIELDS.ROLE_OTHER)}
                                                        validationText={errors[TRIAL_FORM_FIELDS.ROLE_OTHER]}
                                                        value={values[TRIAL_FORM_FIELDS.ROLE_OTHER]}
                                                    />
                                                )}
                                            </div>
                                            <>
                                                <GDSDropdown
                                                    handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.TEAM, item)}
                                                    isRequired={true}
                                                    label={TRIAL_FORM_LABELS.TEAM}
                                                    options={TRIAL_FORM_TEAMS}
                                                    validationText={errors[TRIAL_FORM_FIELDS.TEAM]}
                                                    value={values[TRIAL_FORM_FIELDS.TEAM]}
                                                />
                                                {values[TRIAL_FORM_FIELDS.TEAM] === TRIAL_FORM_OTHER_VALUE.value && (
                                                    <GDSInput
                                                        className="trial-form-team-other"
                                                        handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.TEAM_OTHER, item)}
                                                        isRequired={true}
                                                        label={TRIAL_FORM_LABELS.ANSWER_OTHER_PLEASE_SPECIFY}
                                                        validateInput={() => validateForm(TRIAL_FORM_FIELDS.TEAM_OTHER)}
                                                        validationText={errors[TRIAL_FORM_FIELDS.TEAM_OTHER]}
                                                        value={values[TRIAL_FORM_FIELDS.TEAM_OTHER]}
                                                    />
                                                )}
                                            </>
                                            <GDSDropdown
                                                handleChange={(item) => handleFormChange(TRIAL_FORM_FIELDS.COUNTRY_COVERAGE, item)}
                                                isRequired={true}
                                                label={TRIAL_FORM_LABELS.COUNTRY_COVERAGE}
                                                options={getCountryOptions()}
                                                validationText={errors[TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]}
                                                value={values[TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]}
                                            />
                                            <Form.Check custom id="terms-checkbox" className="gds-checkbox">
                                                <Form.Check.Input
                                                    checked={values[TRIAL_FORM_FIELDS.TERMS_OF_USE]}
                                                    onChange={(e) => handleFormChange(TRIAL_FORM_FIELDS.TERMS_OF_USE, e?.target?.checked)}
                                                    type="checkbox"
                                                />
                                                <Form.Check.Label>{TRIAL_FORM_AGREE_TO_TERMS}</Form.Check.Label>
                                            </Form.Check>
                                            <div className="trial-form-all-fields-required">
                                                <FontAwesomeIcon icon="circle" />
                                                <span className="trial-form-all-fields-reuiqred-message">{TRIAL_FORM_ALL_FIELDS_REQUIRED}</span>
                                            </div>
                                            <Button className="trial-form-signup-button" variant='gds-primary' disabled={!isValid || !dirty || btnDisabled} onClick={handleSubmit}>{TRIAL_FORM_SUBMIT_BUTTON_TEXT}</Button>
                                        </GDSCard>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </>
            )}
            <div className='footer-content-container'>
                <div className='footer-text'>{getCopyRightText()}</div>
                <div>
                    {LANDING_FOOTER_LINKS.map((footerLink, index) => {
                        return <a href={footerLink.path} className='footer-link' key={index} target="_blank" rel="noreferrer">{footerLink.title}</a>
                    })}
                </div>
            </div>
        </div>
    )
};

export default TrialForm;
