import { object, string } from "yup";
import { INVALID_EMAIL_DOMAINS, TRIAL_FORM_INVALID_EMAIL } from "./TrialFormValidation";
import { caseInsensitiveSort } from "../utils/AdminPanelUtils";

export const TRANSFER_FORM_MAX_CHARACTER_LIMIT = 200;
export const TRANSFER_FORM_USE_COMPANY_EMAIL_TEXT = "Please use new user's company email";
export const TRANSFER_FORM_ALL_FIELDS_REQUIRED = "All fields are required";
export const TRANSFER_FORM_HEADER_TEXT = "Enter the information of the new user below.";
export const TRANSFER_FORM_LOADING_MESSAGE = "Fetching great things your way, do not exit this section or refresh the page!";
export const TRANSFER_USER_THANK_YOU_HEADER = "Thank you!";
export const TRANSFER_USER_SUBMISSION_ERROR_TEXT = "Sorry, there was an error in transferring the user. ";
export const TRANSFER_USER_SUBMISSION_ERROR_LINK = "Try again.";
export const TRANSFER_USER_THANK_YOU_MESSAGE = "The new user will receive account credentials shortly via email.";
export const TRANSFER_USER_THANK_YOU_MESSAGE_SUCCESS_SUPPORT = "If the new user did not receive credentials or for any other support, reach out to ";
export const TRANSFER_USER_THANK_YOU_MESSAGE_ERROR_SUPPORT = "If the problem persists, please email ";
export const TRANSFER_USER_SUPPORT_EMAIL = "GracenoteViewSupport@nielsen.com";

export const ADMIN_PANEL_LABELS = {
    HEADER_TITLE: 'Admin Panel',
    NO_DATA_MESSAGE: 'Users list is empty',
    TRANSFER_USER: 'Transfer User'
};


export const ADMIN_PANEL_COLUMNS = [
    {
        disableSortBy: true,
        id: 'groupName',
        accessor: 'groupName',
        Header: 'Group Name'
    },
    {
        disableSortBy: false,
        id: 'userName',
        accessor: 'userName',
        Header: 'User Name',
        sortType: (prev, curr, columnId) => {
            return caseInsensitiveSort(prev, curr, columnId);
        }
    },
    {
        disableSortBy: false,
        id: 'logins',
        accessor: 'logins',
        Header: 'Logins Last 30 Days'
    },
    {
        id: 'transferUser',
        accessor: 'id',
        disableSortBy: true
    }
];

export const TRANSFER_USER_LABELS = {
    TRANSFER_USER: 'Transfer User'
}

export const TRANSFER_FORM_ROLES = [
    { name: "Editorial", value: "Editorial" },
    { name: "Marketing", value: "Marketing" },
    { name: "Content Strategy", value: "Content Strategy" },
    { name: "Program Research", value: "Program Research" },
    { name: "Engineering", value: "Engineering" },
    { name: "Data Science", value: "Data Science" },
    { name: "Product Manager", value: "Product Manager" },
    { name: "Partner Management", value: "Partner Management" },
    { name: "Content Operations", value: "Content Operations" }
];

export const TRANSFER_FORM_TEAMS = [
    { name: "Video on Demand", value: "Video on Demand" },
    { name: "Linear/Live", value: "Linear/Live" },
    { name: "Sports", value: "Sports" }
];

export const TRANSFER_FORM_LABELS = {
    EXISTING_USERS_EMAIL_ADDRESS: "Existing User's Email Address",
    NEW_USERS_EMAIL_ADDRESS: "New User's Email Address",
    FIRST_NAME: "New User's First Name",
    LAST_NAME: "New User's Last Name",
    ROLE: "New User's Role",
    TEAM: "New User's Team"
};

export const TRANSFER_FORM_FIELDS = {
    EXISTING_USERS_EMAIL_ADDRESS: "existingEmailAddress",
    NEW_USERS_EMAIL_ADDRESS: "newUsersEmailAddress",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    ROLE: "role",
    TEAM: "team"
};

export const TRANSFER_FORM_INITIAL_VALUES = {
    [TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS]: "",
    [TRANSFER_FORM_FIELDS.FIRST_NAME]: "",
    [TRANSFER_FORM_FIELDS.LAST_NAME]: "",
    [TRANSFER_FORM_FIELDS.ROLE]: "",
    [TRANSFER_FORM_FIELDS.TEAM]: ""
};

export const TRANSFER_FORM_INITIAL_TOUCHED = {
    [TRANSFER_FORM_FIELDS.EXISTING_USERS_EMAIL_ADDRESS]: true,
    [TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS]: false,
    [TRANSFER_FORM_FIELDS.FIRST_NAME]: false,
    [TRANSFER_FORM_FIELDS.LAST_NAME]: false,
    [TRANSFER_FORM_FIELDS.ROLE]: false,
    [TRANSFER_FORM_FIELDS.TEAM]: false
};

export const TRANSFER_FORM_VALIDATION_SCHEMA = object({
    [TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS]: string()
        .required(' ') // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
        .email('Email address must be valid')
        .min(1, '(Minimum of ${min} Character)')
        .max(TRANSFER_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .test(TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS, TRIAL_FORM_INVALID_EMAIL, function(value, {createError, path}) {
            const inputDomain = value?.split('@')?.[1] || '';
            if (inputDomain.length > 0 && INVALID_EMAIL_DOMAINS.includes(inputDomain.split('.')?.[0])) {
                return createError(TRIAL_FORM_INVALID_EMAIL, path)
            }
            return true
        }),
    [TRANSFER_FORM_FIELDS.FIRST_NAME]: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(TRANSFER_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .required(' '), // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
    [TRANSFER_FORM_FIELDS.LAST_NAME]: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(TRANSFER_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .required(' '), // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
    [TRANSFER_FORM_FIELDS.ROLE]: string().required(),
    [TRANSFER_FORM_FIELDS.TEAM]: string().required()
});