// GN View Auth
export const GVAUTH_FETCH_IN_PROGRESS = 'gvauth/FETCH_IN_PROGRESS';
export const GVAUTH_FETCH_SUCCESS = 'gvauth/FETCH_SUCCESS';
export const GVAUTH_FETCH_ERROR = 'gvauth/FETCH_ERROR';
export const GVAUTH_FETCH_CLEAR = 'gvauth/FETCH_CLEAR';
export const GVAUTH_MENU_SET_LAST_VISITED_URL = 'gvauth/MENU_SET_LAST_VISITED_URL'

// User Settings
export const GVAUTH_USER_SETTINGS_IN_PROGRESS = 'gvauth/GNVIEW_USER_SETTINGS_IN_PROGRESS';
export const GVAUTH_USER_SETTINGS_SUCCESS = 'gvauth/GNVIEW_USER_SETTINGS_SUCCESS';
export const GVAUTH_USER_SETTINGS_FAIL = 'gvauth/GNVIEW_USER_SETTINGS_FAIL';

// Account Modal
export const GVAUTH_TOGGLE_ACCOUNT_MODAL_SHOW = 'gvauth/TOGGLE_ACCOUNT_MODAL_SHOW';
export const GVAUTH_SET_ACCOUNT_MODAL_SELECTED_TAB = 'gvauth/SET_ACCOUNT_MODAL_SELECTED_TAB';

// Admin Panel
export const GNVIEW_TOGGLE_ADMIN_PANEL_SHOW = 'gvauth/TOGGLE_ADMIN_PANEL_SHOW';
export const GNVIEW_ADMIN_PANEL_USERS_LOADING = 'gvauth/ADMIN_PANEL_USERS_LOADING';
export const GNVIEW_ADMIN_PANEL_USERS_LOADING_SUCCESS = 'gvauth/ADMIN_PANEL_USERS_LOADING_SUCCESS';
export const GNVIEW_ADMIN_PANEL_USERS_LOADING_FAILURE = 'gvauth/ADMIN_PANEL_USERS_LOADING_FAILURE';
export const GVAUTH_SET_TRANSFER_USER_SUCCESS = 'gvauth/SET_TRANSFER_USER_SUCCESS';

// Streaming Video Catalogs
export const GNVIEW_CATALOGS_IN_PROGRESS = 'gnvcatalogs/CATALOGS_IN_PROGRESS';
export const GNVIEW_CATALOGS_SUCCESS = 'gnvcatalogs/CATALOGS_SUCCESS';
export const GNVIEW_CATALOGS_FAIL = 'gnvcatalogs/CATALOGS_FAIL';
export const GNVIEW_CATALOGS_CLEAR = 'gnvcatalogs/CATALOGS_CLEAR';

export const MOBIUS_ADD_ENTITLEMENT_LANGUAGES = 'mobius/ENTITLEMENT_LANGUAGES';
export const MOBIUS_ADD_ENTITLEMENT_LANGUAGES_FAIL = 'mobius/ENTITLEMENT_LANGUAGES_FAIL';

// Trial Signup
export const GVAUTH_SET_TRIAL_SIGNUP_SUCCESS = 'gvauth/SET_TRIAL_SIGNUP_SUCCESS';
export const GVAUTH_SET_TRIAL_SIGNUP_VALUES = 'gvauth/SET_TRIAL_SIGNUP_VALUES';
