import {
    GVAUTH_FETCH_IN_PROGRESS,
    GVAUTH_FETCH_SUCCESS,
    GVAUTH_FETCH_CLEAR,
    GVAUTH_FETCH_ERROR,
    GVAUTH_USER_SETTINGS_FAIL,
    GVAUTH_USER_SETTINGS_IN_PROGRESS,
    GVAUTH_USER_SETTINGS_SUCCESS,
    GVAUTH_TOGGLE_ACCOUNT_MODAL_SHOW,
    GVAUTH_SET_ACCOUNT_MODAL_SELECTED_TAB,
    GVAUTH_MENU_SET_LAST_VISITED_URL,
    MOBIUS_ADD_ENTITLEMENT_LANGUAGES,
    MOBIUS_ADD_ENTITLEMENT_LANGUAGES_FAIL,
    GVAUTH_SET_TRIAL_SIGNUP_SUCCESS,
    GVAUTH_SET_TRIAL_SIGNUP_VALUES,
    GNVIEW_TOGGLE_ADMIN_PANEL_SHOW,
    GNVIEW_ADMIN_PANEL_USERS_LOADING,
    GNVIEW_ADMIN_PANEL_USERS_LOADING_SUCCESS,
    GNVIEW_ADMIN_PANEL_USERS_LOADING_FAILURE,
    GVAUTH_SET_TRANSFER_USER_SUCCESS
} from '../actions/ActionTypes';
import { APP_TYPES, MOBIUS_ACCESS_TYPES } from '../constants/App';
import { ACCOUNT_SETTINGS_TAB_OPTIONS } from '../constants/AccountSettings';

export const GNVAUTH_STORE_NAME = 'gvauth';

export const MEDIACLOUD_DOMAIN = 'tmsimg.com';

/**
 * Initial State
 */
export const initialState = {
    accountModalShow: false,
    adminPanel: {
        adminPanelShow: false,
        adminPanelUsersLoading: false,
        adminPanelUsersLoadingSuccess: false,
        adminPanelUsersLoadingFailure: false,
        groups: null,
        transferUserSuccess: false
    },
    accountModalSelectedTab: ACCOUNT_SETTINGS_TAB_OPTIONS.ACCOUNT_INFO,
    company: null,
    fail: false,
    firstName: null,
    email: null,
    entitlements: {},
    errorMessage: null,
    group: null,
    id: null, // user id from the db
    lastName: null,
    loading: false,
    menuLastURLVisited: {
        [APP_TYPES.GNID]: null,
        [APP_TYPES.GNVIEW]: null
    },
    role: null,
    sid: null,
    team: null,
    token: null,
    trialSignupValues: {},
    trialSignupSuccess: false,
    userSettings: {},
    userId: null
};

/**
 * Reducer
 */
export const GNVAuthReducer = (state = initialState, action) => {
    switch (action.type) {
    case GVAUTH_FETCH_IN_PROGRESS:
        return {...state, loading: true};

    case GVAUTH_FETCH_SUCCESS:
        return {
            ...state,
            ...action.payload,
            fail: false,
            loading: false
        };

    case GVAUTH_FETCH_ERROR:
        return {...state, loading: false, fail: true, errorMessage: action.payload.errorMessage};

    case GVAUTH_FETCH_CLEAR:
        return {...initialState};

    case GVAUTH_USER_SETTINGS_IN_PROGRESS:
        return { ...state, userSettings: {}, error: null };

    case GVAUTH_USER_SETTINGS_SUCCESS:
        return { ...state, userSettings: action.payload.userSettings, error: null };

    case GVAUTH_USER_SETTINGS_FAIL:
        return { ...state, userSettings: {}, error: action.payload.data };

    case GVAUTH_TOGGLE_ACCOUNT_MODAL_SHOW:
        return { ...state, accountModalShow: !state.accountModalShow };

    case GNVIEW_TOGGLE_ADMIN_PANEL_SHOW:
        return { ...state, adminPanel: {
            ...state.adminPanel,
            adminPanelShow: !state.adminPanel.adminPanelShow
        }
        };
    case GNVIEW_ADMIN_PANEL_USERS_LOADING:
        return { ...state, adminPanel: {
            ...state.adminPanel,
            adminPanelUsersLoading: true,
            adminPanelUsersLoadingSuccess: false,
            adminPanelUsersLoadingFailure: false
        }
        };
    case GNVIEW_ADMIN_PANEL_USERS_LOADING_SUCCESS:
        return { ...state, adminPanel: {
            ...state.adminPanel,
            adminPanelUsersLoading: false,
            adminPanelUsersLoadingSuccess: true,
            adminPanelUsersLoadingFailure: false,
            groups: [...action.payload.groups]
        }
        };
    case GNVIEW_ADMIN_PANEL_USERS_LOADING_FAILURE:
        return { ...state, adminPanel: {
            ...state.adminPanel,
            adminPanelUsersLoading: false,
            adminPanelUsersLoadingSuccess: false,
            adminPanelUsersLoadingFailure: true
        }
        };

    case GVAUTH_SET_TRANSFER_USER_SUCCESS:
        return { ...state, adminPanel: {
            ...state.adminPanel,
            transferUserSuccess: action.payload.success
        }
        };

    case GVAUTH_SET_TRIAL_SIGNUP_VALUES:
        return { ...state, trialSignupValues: {...action.payload} };

    case GVAUTH_SET_TRIAL_SIGNUP_SUCCESS:
        return { ...state, trialSignupSuccess: action.payload.success };

    case GVAUTH_SET_ACCOUNT_MODAL_SELECTED_TAB:
        return { ...state, accountModalSelectedTab: action.payload.tab };

    case GVAUTH_MENU_SET_LAST_VISITED_URL:
        return {
            ...state,
            menuLastURLVisited: {
                ...state.menuLastURLVisited,
                [action.payload.appType]: action.payload.url
            }
        };

    case MOBIUS_ADD_ENTITLEMENT_LANGUAGES:
        return {...state, entitlements: {...state.entitlements, mobius: {...state.entitlements?.mobius, include: {...state.entitlements?.mobius?.include, languages: action?.payload}}}, loading: false};
    case MOBIUS_ADD_ENTITLEMENT_LANGUAGES_FAIL:
        return {...state, entitlements: {...state.entitlements, mobius: {...state.entitlements?.mobius, include: {...state.entitlements?.mobius?.include, languages: []}}}, loading: false};
    default:
        return state;
    }
};


/**
 * Selectors
 */

// User Fields
export const gvauthSelCompany = state => state[GNVAUTH_STORE_NAME].company;
export const gvauthSelEmail = state => state[GNVAUTH_STORE_NAME].email;
export const gvauthSelFirstName = state => state[GNVAUTH_STORE_NAME].firstName;
export const gvauthSelGroup = state => state[GNVAUTH_STORE_NAME].group;
export const gvauthSelLastName = state => state[GNVAUTH_STORE_NAME].lastName;
export const gvauthSelRole = state => state[GNVAUTH_STORE_NAME].role;
export const gvauthSelTeam = state => state[GNVAUTH_STORE_NAME].team;
export const gvauthSelAccountType = state => state[GNVAUTH_STORE_NAME].group?.accountType;
export const gvauthUserDbId = state => state[GNVAUTH_STORE_NAME].id;

export const gnviewSelMenuLastURLVisited = state => state[GNVAUTH_STORE_NAME].menuLastURLVisited;

// GNView Entitlements
export const gvauthSelEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements;
export const gvauthSelEntitledCountries = state => {
    const allCountries = state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries;
    // Removing any duplicates
    return allCountries?.filter((countryCode, idx) => allCountries?.indexOf(countryCode) === idx) || [];
};
export const gvauthSelLoading = state => state[GNVAUTH_STORE_NAME].loading;
export const gvauthSelTokenSid = state => state[GNVAUTH_STORE_NAME]?.sid;
export const gvauthSelToken = state => state[GNVAUTH_STORE_NAME].token;
export const gvauthSelFail = state => state[GNVAUTH_STORE_NAME].fail;
export const gvauthSelFailErrorMessage = state => state[GNVAUTH_STORE_NAME].errorMessage;
export const gvauthGetEntitledProgramLanguages = state => state[GNVAUTH_STORE_NAME]?.entitlements?.programs?.include?.languages || [];
export const gvauthHasSchedulesEntitlement = state => state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries?.length > 0;
export const gvauthHasUSAEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries?.includes('USA');
export const gvauthHasExportEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('export');
export const gvauthHasStreamId = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.streamId?.length > 0;
export const gvauthHasMediaCloudEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.imageDomain?.some(domain => domain.includes(MEDIACLOUD_DOMAIN));
export const gvauthHasVideoDescriptorsEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.programs?.include?.videoDescriptor;
export const gvauthGetMediaCloudURL = state => {
    const domain = state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.imageDomain?.length > 0 ? state[GNVAUTH_STORE_NAME].entitlements.global.include.imageDomain[0] : null;
    return domain ? `https://${domain}/assets/` : '';
};
export const gvauthIsAdmin = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('admin');
export const gvauthIsBetaUser = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('beta');

// Mobius
export const gvauthHasMobiusEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access?.length > 0 || state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId?.length > 0;
export const gvauthSelMobiusLanguages = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.languages;
export const gvauthSelMobiusSourceID = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId;
export const gvauthHasMobiusWriteAccess = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access[0] === MOBIUS_ACCESS_TYPES.WRITE;

// User Settings
export const gnviewSelUserSettings = state => state[GNVAUTH_STORE_NAME].userSettings;

// Account Modal
export const gnviewSelAccountModalShow = state => state[GNVAUTH_STORE_NAME].accountModalShow;
export const gnviewSelAccountModalSelectedTab = state => state[GNVAUTH_STORE_NAME].accountModalSelectedTab;

// Admin Panel
export const gvauthSalesfoceAccountId = state => state[GNVAUTH_STORE_NAME].group?.salesforceAccountId;
export const gnviewSelAdminPanelShow = state => state[GNVAUTH_STORE_NAME].adminPanel?.adminPanelShow;
export const gnviewSelAdminPanelUserLoadingLoading = state => state[GNVAUTH_STORE_NAME].adminPanel.adminPanelUsersLoading;
export const gnviewSelAdminPanelUserLoadingSuccess = state => state[GNVAUTH_STORE_NAME].adminPanel.adminPanelUsersLoadingSuccess;
export const gnviewSelAdminPanelUserLoadingFailure = state => state[GNVAUTH_STORE_NAME].adminPanel.adminPanelUsersLoadingFailure;
export const gnviewSelAdminPanelGroups = state => state[GNVAUTH_STORE_NAME].adminPanel?.groups;
export const gnviewSelTransferUserSuccess = state => state[GNVAUTH_STORE_NAME].adminPanel?.transferUserSuccess;

// Trial Signup
export const gnviewSelTrialSignupSuccess = state => state[GNVAUTH_STORE_NAME].trialSignupSuccess;
export const gnviewSelTrialSignupValues = (state) => state[GNVAUTH_STORE_NAME].trialSignupValues;

/**
 * Default export
 */
export default GNVAuthReducer;