import React from 'react';
import { Formik } from 'formik';
import get from 'lodash.get';
import "./TransferForm.scss";
import GDSCard from '../../gds/gdsCard/GDSCard';
import GDSInput from '../../gds/gdsInput/GDSInput';
import GDSDropdown from '../../gds/gdsDropdown/GDSDropdown';
import { Button, Form } from 'react-bootstrap';
import {
    TRANSFER_USER_LABELS,
    TRANSFER_FORM_FIELDS,
    TRANSFER_FORM_LABELS,
    TRANSFER_FORM_INITIAL_TOUCHED,
    TRANSFER_FORM_INITIAL_VALUES,
    TRANSFER_FORM_VALIDATION_SCHEMA,
    TRANSFER_FORM_USE_COMPANY_EMAIL_TEXT,
    TRANSFER_FORM_TEAMS,
    TRANSFER_FORM_ROLES,
    TRANSFER_FORM_HEADER_TEXT,
    TRANSFER_FORM_ALL_FIELDS_REQUIRED
} from '../../../constants/AdminPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export const TransferForm = ({ handleFormSubmit, setStep, transferUserEmail }) => {
    return (
        <div className="gnview-transfer-form-content">
            <FontAwesomeIcon className="transfer-form-back-arrow" icon="arrow-left" onClick={() => setStep(1)} />
            <Formik
                initialValues={TRANSFER_FORM_INITIAL_VALUES}
                initialTouched={TRANSFER_FORM_INITIAL_TOUCHED}
                validationSchema={TRANSFER_FORM_VALIDATION_SCHEMA}
                onSubmit={(values) => handleFormSubmit(values)}>
                {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => {
                    const validateForm = (formName) =>
                        isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                    const handleFormChange = (formName, value) => {
                        if (!touched[formName]) {
                            setFieldTouched(formName, true);
                        }
                        setFieldValue(formName, value);
                    };

                    const handleEmailOnChange = async (item) => {
                        const inputEmail = item.trim().toLowerCase();
                        handleFormChange(TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS, inputEmail);
                    };

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <GDSCard>
                                <h3 className="transfer-user-title">{TRANSFER_USER_LABELS.TRANSFER_USER}</h3>
                                <div className="transfer-user-header-text">{TRANSFER_FORM_HEADER_TEXT}</div>
                                <div className="existing-user-email">
                                    <Form.Label role='label'>
                                        {TRANSFER_FORM_LABELS.EXISTING_USERS_EMAIL_ADDRESS}
                                        <FontAwesomeIcon icon="circle" />
                                    </Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        value={transferUserEmail}
                                        // disable Last Pass icon
                                        data-lpignore={true}
                                    />
                                </div>
                                <GDSInput
                                    handleChange={(item) => handleEmailOnChange(item)}
                                    isRequired={true}
                                    helpText={TRANSFER_FORM_USE_COMPANY_EMAIL_TEXT}
                                    label={TRANSFER_FORM_LABELS.NEW_USERS_EMAIL_ADDRESS}
                                    validateInput={() => validateForm(TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS)}
                                    validationText={errors[TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS]}
                                    value={values[TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS]}
                                />
                                <GDSInput
                                    handleChange={(item) => handleFormChange(TRANSFER_FORM_FIELDS.FIRST_NAME, item)}
                                    isRequired={true}
                                    label={TRANSFER_FORM_LABELS.FIRST_NAME}
                                    validateInput={() => validateForm(TRANSFER_FORM_FIELDS.FIRST_NAME)}
                                    validationText={errors[TRANSFER_FORM_FIELDS.FIRST_NAME]}
                                    value={values[TRANSFER_FORM_FIELDS.FIRST_NAME]}
                                />
                                <GDSInput
                                    handleChange={(item) => handleFormChange(TRANSFER_FORM_FIELDS.LAST_NAME, item)}
                                    isRequired={true}
                                    label={TRANSFER_FORM_LABELS.LAST_NAME}
                                    validateInput={() => validateForm(TRANSFER_FORM_FIELDS.LAST_NAME)}
                                    validationText={errors[TRANSFER_FORM_FIELDS.LAST_NAME]}
                                    value={values[TRANSFER_FORM_FIELDS.LAST_NAME]}
                                />
                                <GDSDropdown
                                    handleChange={(item) => handleFormChange(TRANSFER_FORM_FIELDS.ROLE, item)}
                                    isRequired={true}
                                    label={TRANSFER_FORM_LABELS.ROLE}
                                    options={TRANSFER_FORM_ROLES}
                                    validationText={errors[TRANSFER_FORM_FIELDS.ROLE]}
                                    value={values[TRANSFER_FORM_FIELDS.ROLE]}
                                />
                                <GDSDropdown
                                    handleChange={(item) => handleFormChange(TRANSFER_FORM_FIELDS.TEAM, item)}
                                    isRequired={true}
                                    label={TRANSFER_FORM_LABELS.TEAM}
                                    options={TRANSFER_FORM_TEAMS}
                                    validationText={errors[TRANSFER_FORM_FIELDS.TEAM]}
                                    value={values[TRANSFER_FORM_FIELDS.TEAM]}
                                />
                                <div className="transfer-form-all-fields-required">
                                    <FontAwesomeIcon icon="circle" />
                                    <span className="transfer-form-all-fields-reuiqred-message">{TRANSFER_FORM_ALL_FIELDS_REQUIRED}</span>
                                </div>
                                <Button className="transfer-form-submit-button" variant='gds-primary' disabled={!isValid || !dirty} onClick={handleSubmit}>{TRANSFER_USER_LABELS.TRANSFER_USER}</Button>
                            </GDSCard>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
};

TransferForm.propTypes = {
    handleFormSubmit: PropTypes.func,
    setStep: PropTypes.func,
    transferUserEmail: PropTypes.string
};

export default TransferForm;
