import {Notifier} from "@airbrake/browser";
import {AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY} from '../config/Airbrake';
// NODE_ENV is defined in webpack
/* eslint-disable no-undef */
export default class EnvironmentService {
    getIsInDevMode() {
        if (NODE_ENV === 'dev' || NODE_ENV === 'test') {
            return true;
        }
        return false;
    }

    getAirbrakeClient() {
        const airbrake = new Notifier({
            projectId: AIRBRAKE_PROJECT_ID,
            projectKey: AIRBRAKE_PROJECT_KEY,
            environment: NODE_ENV
        });
        airbrake.addFilter(function (notice) {
            if (notice.context.environment === 'dev' || notice.context.environment === 'test') {
                // Ignore errors from dev
                return null;
            }
            return notice;
        });
        return airbrake;
    }
}
/* eslint-enable no-undef */