
import {
    GNVIEW_CATALOGS_IN_PROGRESS,
    GNVIEW_CATALOGS_SUCCESS,
    GNVIEW_CATALOGS_FAIL,
    GVAUTH_MENU_SET_LAST_VISITED_URL,
    GNVIEW_ADMIN_PANEL_USERS_LOADING,
    GNVIEW_ADMIN_PANEL_USERS_LOADING_SUCCESS,
    GNVIEW_ADMIN_PANEL_USERS_LOADING_FAILURE,
    GNVIEW_TOGGLE_ADMIN_PANEL_SHOW
} from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { OTHER, TRIAL_FORM_FIELDS, TRIAL_FORM_OTHER_VALUE } from '../constants/TrialFormValidation';
import { TRANSFER_FORM_FIELDS } from '../constants/AdminPanel';
import moment from 'moment-timezone';
import { TRIAL_USER_LANGUAGE } from '../constants/Languages';
import { gnviewSendLogMessage } from '../services/GeneralService';

/** Streaming Video Catalogs */
export const gnviewGetEntitledCatalogsInProgress = () => ({
    type: GNVIEW_CATALOGS_IN_PROGRESS
});

export const gnviewGetEntitledCatalogsSuccess = (catalogs, shouldStore) => ({
    type: GNVIEW_CATALOGS_SUCCESS,
    payload: { catalogs, shouldStore }
});

export const gnviewGetEntitledCatalogsFail = (catalogs) => ({
    type: GNVIEW_CATALOGS_FAIL,
    payload: { catalogs }
});

export const gnviewGetStreamingVideoCatalogs = (shouldStore = false) => {
    return (dispatch) => {
        dispatch(gnviewGetEntitledCatalogsInProgress());
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_STREAMING_VIDEO_CATALOGS}`, {}, 'get').then(
            (response) => {
                dispatch(gnviewGetEntitledCatalogsSuccess(response.result, shouldStore));
                return response;
            }, (error) => {
                const data = error?.response?.data || [];
                dispatch(gnviewGetEntitledCatalogsFail(data));
                throw error;
            }
        )
    }
};

/** Admin Panel */
export const gnviewToggleAdminPanelShow = () => ({
    type: GNVIEW_TOGGLE_ADMIN_PANEL_SHOW
});

export const gnviewAdminPanelUsersLoading = () => ({
    type: GNVIEW_ADMIN_PANEL_USERS_LOADING
});

export const gnviewAdminPanelUsersLoadingSuccess = (groups) => ({
    type: GNVIEW_ADMIN_PANEL_USERS_LOADING_SUCCESS,
    payload: { groups }
});

export const gnviewAdminPanelUsersLoadingFailure = (users) => ({
    type: GNVIEW_ADMIN_PANEL_USERS_LOADING_FAILURE,
    payload: { users }
});

export const gnviewGetAdminPanelUsers = (salesforceAccountId) => {
    return (dispatch) => {
        dispatch(gnviewAdminPanelUsersLoading());
        return apiService.makeApiCall(`${API_ENDPOINTS.ENTITLEMENT_GROUPS}?salesforce_account_id=${salesforceAccountId}`, {}, 'get').then(
            (response) => {
                dispatch(gnviewAdminPanelUsersLoadingSuccess(response.result));
                return response;
            }, (error) => {
                const data = error?.response?.data || [];
                dispatch(gnviewAdminPanelUsersLoadingFailure(data));
                throw error;
            }
        )
    }
};

export const gnviewGetTrialEmailDomains = () => {
    return () => {
        return apiService.makeApiCall(API_ENDPOINTS.TRIAL_EMAIL_DOMAINS, {}, 'get')
            .then(
                (response) => {
                    return response.result;
                },
                (error) => {
                    throw error;
                }
            );
    };
};

export const gnviewGetTrialCompanies = () => {
    return () => {
        return apiService.makeApiCall(API_ENDPOINTS.TRIAL_COMPANIES, {}, 'get')
            .then(
                (response) => {
                    return response.result;
                },
                (error) => {
                    throw error;
                }
            );
    };
};

export const gnviewCreateTrialEntitlementGroup = (values) => {
    return () => {
        const endDate = moment().tz('America/Los_Angeles').add('30', 'days').toISOString();
        const todaysDate = moment();
        const role = values[TRIAL_FORM_FIELDS.ROLE] === TRIAL_FORM_OTHER_VALUE.value ? values[TRIAL_FORM_FIELDS.ROLE_OTHER] : values[TRIAL_FORM_FIELDS.ROLE];
        const team = values[TRIAL_FORM_FIELDS.TEAM] === TRIAL_FORM_OTHER_VALUE.value ? values[TRIAL_FORM_FIELDS.TEAM_OTHER] : values[TRIAL_FORM_FIELDS.TEAM];
        const company = values[TRIAL_FORM_FIELDS.COMPANY] === TRIAL_FORM_OTHER_VALUE.value ? values[TRIAL_FORM_FIELDS.COMPANY_OTHER] : values[TRIAL_FORM_FIELDS.COMPANY];
        const companyId = values[TRIAL_FORM_FIELDS.COMPANY_ID];
        const body = {
            name: `${todaysDate.format('MMMM D, YYYY')} ${values[TRIAL_FORM_FIELDS.COMPANY] === TRIAL_FORM_OTHER_VALUE.value ? values[TRIAL_FORM_FIELDS.COMPANY_OTHER] : values[TRIAL_FORM_FIELDS.COMPANY]} Website Trial Form`,
            users: [
                {
                    email: `${values[TRIAL_FORM_FIELDS.EMAIL_ADDRESS]}`,
                    first_name: `${values[TRIAL_FORM_FIELDS.FIRST_NAME]}`,
                    last_name: `${values[TRIAL_FORM_FIELDS.LAST_NAME]}`,
                    role,
                    team,
                    company
                }
            ],
            endDate,
            created: todaysDate,
            updated: todaysDate,
            isTrial: true,
            isTrialForm: true,
            salesforceAccountId: companyId,
            accountType: 'trial',
            hasLicense: true
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.ENTITLEMENT_GROUPS}`, body, 'post');
    };
};

export const gnviewDeleteTrialEntitlementGroup = (groupId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.ENTITLEMENT_GROUPS}/${groupId}`, {}, 'delete');
    };
};

export const gnviewUpdateEntitlementGroup = (groupId, body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.ENTITLEMENT_GROUPS}/${groupId}`, body, 'put');
    };
};

export const gnviewUpdateTrialEntitlementExpression = (id, values) => {
    return () => {
        const languages = Object.keys(TRIAL_USER_LANGUAGE);
        const body = {
            entitlementExpression: {
                global: {
                    exclude: {},
                    include: {
                        roles: [],
                        imageDomain: [
                            "demo.tmsimg.com"
                        ]
                    }
                },
                mobius: {
                    exclude: {},
                    include: {
                        access: [],
                        sourceId: [],
                        languages: []
                    }
                },
                programs: {
                    exclude: {},
                    include: {
                        languages,
                        videoDescriptor: true
                    }
                },
                schedules: {
                    exclude: {},
                    include: {
                        countries: [
                            `${values[TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]}`
                        ]
                    }
                },
                streamingVideos: {
                    exclude: {
                        catalogs: []
                    },
                    include: {
                        catalogs: []
                    }
                }
            }
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.ENTITLEMENT_GROUPS}/${id}/${API_ENDPOINTS.ENTITLEMENTS_EXPRESSION}`, body, 'put');
    };
};

export const gnviewCreateTrialCognitoUser = (values) => {
    return () => {
        const company = values?.[TRIAL_FORM_FIELDS.COMPANY] !== OTHER ? values[TRIAL_FORM_FIELDS.COMPANY] : values[TRIAL_FORM_FIELDS.COMPANY_OTHER];
        const firstName = values[TRIAL_FORM_FIELDS.FIRST_NAME];
        const lastName = values[TRIAL_FORM_FIELDS.LAST_NAME];
        const commaOrSpace = /[\s,]/g;
        const replacement = '';
        const companyEscaped = company.replace(commaOrSpace, replacement);
        const firstNameEscaped = firstName.replace(commaOrSpace, replacement);
        const lastNameEscaped = lastName.replace(commaOrSpace, replacement);
        const body = {
            company: companyEscaped,
            country: values[TRIAL_FORM_FIELDS.COUNTRY_COVERAGE],
            email: values[TRIAL_FORM_FIELDS.EMAIL_ADDRESS],
            firstName: firstNameEscaped,
            isTrial: true,
            lastName: lastNameEscaped
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.COGNITO_USER}`, body, 'post');
    };
};

export const gnviewCreateTransferCognitoUser = (company, values) => {
    return () => {
        const firstName = values[TRANSFER_FORM_FIELDS.FIRST_NAME];
        const lastName = values[TRANSFER_FORM_FIELDS.LAST_NAME];
        const commaOrSpace = /[\s,]/g;
        const replacement = '';
        let companyEscaped = ''
        try {
            companyEscaped = company.replace(commaOrSpace, replacement);
        } catch (e) {
            gnviewSendLogMessage('Error parsing company', e)
            companyEscaped = '';
        }
        const firstNameEscaped = firstName.replace(commaOrSpace, replacement);
        const lastNameEscaped = lastName.replace(commaOrSpace, replacement);
        const body = {
            company: companyEscaped,
            email: values[TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS],
            firstName: firstNameEscaped,
            lastName: lastNameEscaped,
            isTransfer: true
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.COGNITO_USER}`, body, 'post');
    };
};

export const gnviewCreateSalesforceContact = (body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SALESFORCE_CONTACT}`, body, 'post');
    };
};

export const gnviewSetMenuLastURLVisited = (appType, url) => ({
    type: GVAUTH_MENU_SET_LAST_VISITED_URL,
    payload: { appType, url }
});
