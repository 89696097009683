import {
    GVAUTH_FETCH_IN_PROGRESS,
    GVAUTH_FETCH_SUCCESS,
    GVAUTH_FETCH_ERROR,
    GVAUTH_FETCH_CLEAR,
    GVAUTH_USER_SETTINGS_IN_PROGRESS,
    GVAUTH_USER_SETTINGS_SUCCESS,
    GVAUTH_USER_SETTINGS_FAIL,
    GVAUTH_TOGGLE_ACCOUNT_MODAL_SHOW,
    GVAUTH_SET_ACCOUNT_MODAL_SELECTED_TAB,
    MOBIUS_ADD_ENTITLEMENT_LANGUAGES,
    MOBIUS_ADD_ENTITLEMENT_LANGUAGES_FAIL,
    GVAUTH_SET_TRIAL_SIGNUP_SUCCESS,
    GVAUTH_SET_TRIAL_SIGNUP_VALUES,
    GVAUTH_SET_TRANSFER_USER_SUCCESS
} from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { decodeToken } from "react-jwt";
import { GNVIEW_SETTINGS } from '../constants/LocalStorage';

export const gnviewAuthFetchInProgress = () => ({
    type: GVAUTH_FETCH_IN_PROGRESS
});

export const gnviewAuthFetchSuccess = (userInfo) => ({
    type: GVAUTH_FETCH_SUCCESS,
    payload: {
        ...userInfo
    }
});

export const gnviewAuthFetchError = (error) => ({
    type: GVAUTH_FETCH_ERROR,
    payload: {
        errorMessage: error || null
    }
});

export const gnviewGetEntitlements = (idToken, accessToken) => {
    return (dispatch) => {
        const query = {
            id_token: idToken,
            access_token: accessToken
        };
        dispatch(gnviewAuthFetchInProgress());
        return apiService.makeApiCall(API_ENDPOINTS.ENTITLEMENTS, query, 'get')
            .then((response) => {
                const decodedToken = decodeToken(response.result);
                // eslint-disable-next-line babel/camelcase
                const {company, first_name, last_name, role, team, user_id, ...user} = decodedToken
                const userInfo = {
                    ...user,
                    company,
                    email: user.username,
                    firstName: first_name,
                    lastName: last_name,
                    role,
                    team,
                    token: response.result,
                    userId: user_id
                }
                dispatch(gnviewAuthFetchSuccess(userInfo));
                return userInfo;
            }).catch((error) => {
                dispatch(gnviewAuthFetchError(error));
                throw error;
            });
    }
};

export const gnviewClearState = () => ({
    type: GVAUTH_FETCH_CLEAR
});

/** User Settings */
export const gnviewUserSettingsInProgress = () => ({
    type: GVAUTH_USER_SETTINGS_IN_PROGRESS
});

export const gnviewUserSettingsSuccess = (userSettings) => ({
    type: GVAUTH_USER_SETTINGS_SUCCESS,
    payload: { userSettings }
});

export const gnviewUserSettingsFail = (data) => ({
    type: GVAUTH_USER_SETTINGS_FAIL,
    payload: { data }
});

export const gnviewGetUserSettings = (username) => {
    return (dispatch) => {
        dispatch(gnviewUserSettingsInProgress());
        return apiService.makeApiCall(`${API_ENDPOINTS.USER_SETTINGS}/${username}`, {}, 'get')
            .then((response) => {
                dispatch(gnviewUserSettingsSuccess(response?.result));
                localStorage.setItem(GNVIEW_SETTINGS, JSON.stringify(response?.result));
                return response;
            }).catch((error) => {
                const data = error?.response?.data || [];
                dispatch(gnviewUserSettingsFail(data));
                throw error;
            });
    }
};

export const gnviewUpdateUserSettings = (email, body) => {
    return (dispatch) => {
        dispatch(gnviewUserSettingsInProgress());
        return apiService.makeApiCall(`${API_ENDPOINTS.USER_SETTINGS}/${email}`, body, 'put')
            .then((response) => {
                dispatch(gnviewUserSettingsSuccess(response?.result));
                localStorage.setItem(GNVIEW_SETTINGS, JSON.stringify(response?.result));
                return response;
            }).catch((error) => {
                const data = error?.response?.data || [];
                dispatch(gnviewUserSettingsFail(data));
                throw error;
            });
    }
};

// Account Modal
export const gnviewToggleAccountModalShow = () => ({
    type: GVAUTH_TOGGLE_ACCOUNT_MODAL_SHOW
});

export const gnviewSetAccountModalTab = (tab) => ({
    type: GVAUTH_SET_ACCOUNT_MODAL_SELECTED_TAB,
    payload: { tab }
});

// Trial Signup
export const gnviewSetTrialSignupSuccess = (success) => ({
    type: GVAUTH_SET_TRIAL_SIGNUP_SUCCESS,
    payload: { success }
});

export const gnviewSetTrialSignupValues = (trialSignupValues) => ({
    type: GVAUTH_SET_TRIAL_SIGNUP_VALUES,
    payload: trialSignupValues
});

// Transfer User
export const gnviewSetTransferUserSuccess = (success) => ({
    type: GVAUTH_SET_TRANSFER_USER_SUCCESS,
    payload: { success }
});

// Entitlements
export const mobiusAddEntitlementLanguages = (languages) => ({
    type: MOBIUS_ADD_ENTITLEMENT_LANGUAGES,
    payload: languages
});

export const mobiusAddEntitlementLanguagesFail = (data) => ({
    type: MOBIUS_ADD_ENTITLEMENT_LANGUAGES_FAIL,
    payload: data
});

export const mobiusGetEntitlementLanguages = (sourceId) => {
    return (dispatch) => {
        return apiService
            .makeApiCall(
                `${API_ENDPOINTS.ENTITLEMENTS_SOURCES}/${sourceId}/${API_ENDPOINTS.ENTITLEMENTS_EXPRESSION}`,
                {},
                'get',
                false
            )
            .then(
                (response) => {
                    const languages =
                        response?.result?.entitlementExpression?.programs?.include?.languages;
                    dispatch(mobiusAddEntitlementLanguages(languages));
                    return languages;
                },
                (error) => {
                    const data = error?.response?.data || {};
                    dispatch(mobiusAddEntitlementLanguagesFail(data));
                    throw error;
                }
            );
    };
};