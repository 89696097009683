import React, { useEffect, useState } from 'react';
import { Form, Popover } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import './GDSInput.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GDSInput = (props) => {
    const value = props?.value || '';
    const [inputValue, setValue] = useState(value);
    useEffect(() => {
        if (inputValue !== value) {
            setValue(value);
        }
    }, [inputValue, value]);

    const infoPopover = (
        <Popover>
            <Popover.Content>{props?.infoMessage}</Popover.Content>
        </Popover>
    );

    return (
        <div className={ClassNames('gds-input-container', { [props.className]: props.className })}>
            <InputGroup {...(props?.isLarge && { className: 'large-size-input' })}>
                {props?.label && (
                    <Form.Label role='label'>
                        {props.label}
                        {props?.infoMessage && (
                            <OverlayTrigger trigger='hover' placement='top' overlay={infoPopover} delay={500}>
                                <FontAwesomeIcon icon="info-circle" />
                            </OverlayTrigger>
                        )}
                        {props.isRequired && <FontAwesomeIcon icon="circle" />}
                    </Form.Label>
                )}
                {(props?.errorMessage?.length > 0 || props?.validationText?.length > 0) && <span className='error-message'>{props?.errorMessage || props?.validationText}</span>}
                {props.type === 'textarea' && (
                    <Form.Text className='textarea-max'>{`${inputValue.length}/${props.textAreaMax}`}</Form.Text>
                )}
                {props?.helpText && !props.errorMessage && !props.validationText && (
                    <Form.Label className="help-text">
                        {props.helpText}
                    </Form.Label>
                )}
                <div className='gds-input-form-container'>

                    <Form.Control
                        as={props.type}
                        placeholder={props?.placeholder}
                        disabled={props?.disabled || false}
                        value={inputValue}
                        onChange={(e) => {
                            props.handleChange(e.target.value);
                            setValue(e.target.value);
                        }}
                        // disable Last Pass icon
                        data-lpignore={true}
                        {...(props?.textAreaRows && { rows: props.textAreaRows })}
                        {...(!props?.validateInput(inputValue) && { className: 'invalid' })}
                    />
                    {inputValue.length > 0 && !props.disabled && (
                        <i
                            className={ClassNames('fas fa-xmark', { 'has-label': props?.label, 'has-help-text': props?.helpText })}
                            role='clear-button'
                            onClick={() => {
                                props.handleChange('');
                                setValue('');
                            }}
                        />
                    )}
                </div>
            </InputGroup>
        </div>
    );
};

GDSInput.defaultProps = {
    className: '',
    helpText: '',
    isLarge: false,
    isRequired: false,
    type: 'input',
    validateInput: () => {}
};

GDSInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    helpText: PropTypes.string,
    infoMessage: PropTypes.string,
    isLarge: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    textAreaMax: PropTypes.number,
    textAreaRows: PropTypes.number,
    type: PropTypes.string,
    validateInput: PropTypes.func.isRequired,
    validationText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GDSInput;
