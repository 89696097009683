import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import "./GNConfirmationModal.scss";

export const GNConfirmationModal = (props) => {
    return (
        <Modal show={props.show} centered dialogClassName="gnview-confirmation-modal">
            {props.title && <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
            </Modal.Header>}
            {props.message && <Modal.Body>
                {props.message}
            </Modal.Body>}
            <Modal.Footer>
                <Button variant="secondary-text" onClick={props.cancelButtonHandler}>
                    {props.cancelButtonText}
                </Button>
                <Button variant="primary-text" onClick={props.submitButtonHandler}>
                    {props.submitButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

GNConfirmationModal.propTypes = {
    cancelButtonHandler: PropTypes.func,
    cancelButtonText: PropTypes.string,
    message: PropTypes.string,
    show: PropTypes.bool.isRequired,
    submitButtonHandler: PropTypes.func,
    submitButtonText: PropTypes.string,
    title: PropTypes.string
};

export default GNConfirmationModal;