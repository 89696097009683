import React from 'react';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { PRIVACY_POLICY } from '../../constants/PrivacyPolicy';
import gnViewLogo from '../../images/GN_View_logo.png';
import './PrivacyPolicy.scss'

export const PrivacyPolicy = () => {
    return (
        <ErrorBoundary>
            <div className="gnview-privacy-policy-container">
                <img src={gnViewLogo} className='gnview-logo' alt='Gracenote View Logo'/>
                <div className="title">{PRIVACY_POLICY.TITLE}</div>
                <div className="top-secton" >
                    {PRIVACY_POLICY.TOP_SECTION}
                    <a href="https://www.nielsen.com/legal/gracenote-services-privacy-statement/">Privacy Notice.</a>
                </div>
            </div>
        </ErrorBoundary>
    )
}
export default PrivacyPolicy