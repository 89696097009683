import countries from "i18n-iso-countries";
import { LANGUAGES } from '../constants/Languages';
import { ROUTES } from '../config/Routes';
import {
    GNVIEW_AUTH,
    GNVIEW_SETTINGS,
    GNVIEW_MENU_STATE
} from '../constants/LocalStorage';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Converts an array of country codes ['USA'] to an array with code and name [{code: 'USA', name: 'United States of America'}]
export const convertCountryCodes = (countryCodes) => {
    const countryNameAndCodes = countryCodes?.reduce((result, code) => {
        // Only add the country if we can convert the country code to name
        if (countries.getName(code, 'en')) {
            result.push({ code, name: countries.getName(code, 'en') });
        }
        return result;
    }, []);

    // Sort alphabetically a-z
    return countryNameAndCodes?.sort((a, b) => a.name > b.name ? 1 : -1);
};

export const getCountryOptions = () => {
    const countryAlpha3Codes = countries.getAlpha3Codes();
    return Object.keys(countryAlpha3Codes)
        .map((c) => ({ value: c, name: countries.getName(c, "en") }))
        .sort((a, b) => a.name.localeCompare(b.name));
};

export const getLanguageOptions = () => {
    return Object.entries(LANGUAGES)
        .map(([key, val]) => ({ name: val, value: key }))
        .sort((a, b) => a.name.localeCompare(b.name));
};

export const convertLanguageFromCode = (code) => {
    return LANGUAGES[code] || code || '';
};

export const convertLanguageCodeList = languageCodes => languageCodes?.length > 0 ? languageCodes.map(code => convertLanguageFromCode(code)) : [];

export const getPathAfterLogin = (entitlements) => {
    if (entitlements?.schedules?.include?.countries?.length > 0) {
        return ROUTES.SCHEDULES;
    } else if (entitlements?.programs?.include?.languages?.length > 0) {
        return ROUTES.PROGRAM_DETAILS;
    } else if (entitlements?.mobius?.include?.access?.length > 0) {
        return ROUTES.GNID_DISTRIBUTION;
    } else {
        return ROUTES.PROGRAM_DETAILS;
    }
}

export const cleanUpLocalStorage = () => {
    localStorage.removeItem(GNVIEW_AUTH);
    localStorage.removeItem(GNVIEW_SETTINGS);
    localStorage.removeItem(GNVIEW_MENU_STATE);
}

export const capitalizeFirstLetter = (str) => typeof (str) === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : null;

export const numberWithCommas = (num) => {
    return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
};