import { boolean, object, string } from "yup";
import React from 'react';
import { ROUTES } from "../config/Routes";

export const OTHER = "Other";
export const OTHER_REQUIREMENT = () => ({
    is: (val) => val === OTHER,
    then: string().required(' ')
});
export const TRIAL_FORM_DEFAULT_COUNTRY_CODE = "USA";
export const TRIAL_FORM_HEADER_TEXT = "Sign Up for a 30-day Free Trial";
export const TRIAL_FORM_USE_COMPANY_EMAIL_TEXT = "Please use your company email";
export const TRIAL_FORM_MAX_CHARACTER_LIMIT = 200;
export const TRIAL_FORM_SUBMIT_BUTTON_TEXT = "Sign up";
export const TRIAL_FORM_THANK_YOU_HEADER = "Thank you for signing up!";
export const TRIAL_FORM_SUBMISSION_ERROR_TEXT = "Sorry, there was an error in creating your trial. ";
export const TRIAL_FORM_SUBMISSION_ERROR_LINK = "Try again.";
export const TRIAL_FORM_THANK_YOU_MESSAGE = "You will receive your account credentials shortly via email. Enjoy Gracenote View!";
export const TRIAL_FORM_THANK_YOU_MESSAGE_SUCCESS_SUPPORT = "If you do not receive your credentials or for any other support reach out to ";
export const TRIAL_FORM_THANK_YOU_MESSAGE_ERROR_SUPPORT = "If the problem persists, please email ";
export const TRIAL_FORM_SUPPORT_EMAIL = "gracenotetrialsupport@nielsen.com";
export const TRIAL_FORM_NOT_CUSTOMER = "Your company is not a Gracenote Video customer. Please Request a Demo";
export const TRIAL_FORM_INVALID_EMAIL = "You must input a company email";
export const TRIAL_FORM_ALL_FIELDS_REQUIRED = "All fields are required";
export const TRIAL_FORM_LOADING_MESSAGE = "Fetching great things your way, do not refresh the page!";
export const TRIAL_FORM_AGREE_TO_TERMS = <span>I agree to <a href={ROUTES.TERMS_OF_USE} className='footer-link' target="_blank" rel="noreferrer">website terms</a> and to comply with the terms of my company&apos;s existing contract with Gracenote.</span>;

export const TRIAL_FORM_OTHER_VALUE = {name: OTHER, value: OTHER};

export const TRIAL_FORM_LABELS = {
    ANSWER_OTHER_PLEASE_SPECIFY: "If your answer is 'Other', please specify: ",
    COMPANY: "Company",
    COUNTRY_COVERAGE: "Country Coverage",
    EMAIL_ADDRESS: "Email Address",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    ROLE: "Role",
    TEAM: "Team"
};

export const TRIAL_FORM_ROLES = [
    { name: "Editorial", value: "Editorial" },
    { name: "Marketing", value: "Marketing" },
    { name: "Content Strategy", value: "Content Strategy" },
    { name: "Program Research", value: "Program Research" },
    { name: "Engineering", value: "Engineering" },
    { name: "Data Science", value: "Data Science" },
    { name: "Product Manager", value: "Product Manager" },
    { name: "Partner Management", value: "Partner Management" },
    { name: "Content Operations", value: "Content Operations" },
    { name: OTHER, value: OTHER }
];

export const TRIAL_FORM_TEAMS = [
    { name: "Video on Demand", value: "Video on Demand" },
    { name: "Linear/Live", value: "Linear/Live" },
    { name: "Sports", value: "Sports" },
    { name: OTHER, value: OTHER }
];

export const TRIAL_FORM_FIELDS = {
    EMAIL_ADDRESS: "emailAddress",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    COMPANY: "company",
    COMPANY_OTHER: "companyOther",
    COMPANY_ID: "companyId",
    ROLE: "role",
    ROLE_OTHER: "roleOther",
    TEAM: "team",
    TEAM_OTHER: "teamOther",
    COUNTRY_COVERAGE: "countryCoverage",
    TERMS_OF_USE: "termsOfUse"
};

export const INVALID_EMAIL_DOMAINS = [
    "gmail",
    "hotmail",
    "yahoo",
    "outlook"
];

export const TRIAL_FORM_INITIAL_VALUES = {
    [TRIAL_FORM_FIELDS.EMAIL_ADDRESS]: "",
    [TRIAL_FORM_FIELDS.FIRST_NAME]: "",
    [TRIAL_FORM_FIELDS.LAST_NAME]: "",
    [TRIAL_FORM_FIELDS.COMPANY]: "",
    [TRIAL_FORM_FIELDS.COMPANY_ID]: "",
    [TRIAL_FORM_FIELDS.COMPANY_OTHER]: "",
    [TRIAL_FORM_FIELDS.ROLE]: "",
    [TRIAL_FORM_FIELDS.ROLE_OTHER]: "",
    [TRIAL_FORM_FIELDS.TEAM]: "",
    [TRIAL_FORM_FIELDS.TEAM_OTHER]: "",
    [TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]: TRIAL_FORM_DEFAULT_COUNTRY_CODE,
    [TRIAL_FORM_FIELDS.TERMS_OF_USE]: false
};

export const TRIAL_FORM_INITIAL_TOUCHED = {
    [TRIAL_FORM_FIELDS.EMAIL_ADDRESS]: false,
    [TRIAL_FORM_FIELDS.FIRST_NAME]: false,
    [TRIAL_FORM_FIELDS.LAST_NAME]: false,
    [TRIAL_FORM_FIELDS.COMPANY]: false,
    [TRIAL_FORM_FIELDS.COMPANY_ID]: "",
    [TRIAL_FORM_FIELDS.COMPANY_OTHER]: false,
    [TRIAL_FORM_FIELDS.ROLE]: false,
    [TRIAL_FORM_FIELDS.ROLE_OTHER]: false,
    [TRIAL_FORM_FIELDS.TEAM]: false,
    [TRIAL_FORM_FIELDS.TEAM_OTHER]: false,
    [TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]: false,
    [TRIAL_FORM_FIELDS.TERMS_OF_USE]: false
};

export const TRIAL_FORM_VALIDATION_SCHEMA = object({
    [TRIAL_FORM_FIELDS.EMAIL_ADDRESS]: string()
        .email()
        .min(1, '(Minimum of ${min} Character)')
        .max(TRIAL_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .required(' '), // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
    [TRIAL_FORM_FIELDS.FIRST_NAME]: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(TRIAL_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .required(' '), // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
    [TRIAL_FORM_FIELDS.LAST_NAME]: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(TRIAL_FORM_MAX_CHARACTER_LIMIT, 'Maximum ${max} Characters')
        .required(' '), // needs to be a space so we still return a truthy value for validateInput and mark the form as invalid
    [TRIAL_FORM_FIELDS.COMPANY]: string().required(),
    [TRIAL_FORM_FIELDS.COMPANY_OTHER]: string()
        .when(
            TRIAL_FORM_FIELDS.COMPANY,
            OTHER_REQUIREMENT()
        ),
    [TRIAL_FORM_FIELDS.ROLE]: string().required(),
    [TRIAL_FORM_FIELDS.ROLE_OTHER]: string()
        .when(
            TRIAL_FORM_FIELDS.ROLE,
            OTHER_REQUIREMENT()
        ),
    [TRIAL_FORM_FIELDS.TEAM]: string().required(),
    [TRIAL_FORM_FIELDS.TEAM_OTHER]: string()
        .when(
            TRIAL_FORM_FIELDS.TEAM,
            OTHER_REQUIREMENT()
        ),
    [TRIAL_FORM_FIELDS.COUNTRY_COVERAGE]: string().required(),
    [TRIAL_FORM_FIELDS.TERMS_OF_USE]: boolean().required().oneOf([true], 'Field must be checked')
});
