import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { gvauthSalesfoceAccountId, gvauthSelEmail, gnviewSelAdminPanelGroups, gnviewSelTransferUserSuccess } from '../../reducers/GNVAuthReducer';
import GNModal from '../gnModal/GNModal';
import ClassNames from 'classnames';
import loadingDoggo from '../../images/LoadingDoggo.gif';
import { gnviewGetAdminPanelUsers, gnviewUpdateEntitlementGroup, gnviewCreateTransferCognitoUser, gnviewCreateSalesforceContact } from '../../actions/GNViewActions';
import { gnviewSetTransferUserSuccess } from '../../actions/GNViewAuthActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import {
    ADMIN_PANEL_COLUMNS,
    ADMIN_PANEL_LABELS,
    TRANSFER_FORM_FIELDS,
    TRANSFER_FORM_LOADING_MESSAGE
} from '../../constants/AdminPanel';
import GNClientTable from '../gnClientTable/GNClientTable';
import { CLIENT_TABLE_PAGE_SIZE } from '../../constants/App';
import { transformGroups } from '../../utils/AdminPanelUtils';
import { Button } from 'react-bootstrap';
import TransferForm from './transferForm/TransferForm';
import './GNAdminPanel.scss';
import moment from 'moment-timezone';
import TransferThankYou from './transferThankYou/TransferThankYou';

export const GNAdminPanel = ({ handleModal, show }) => {
    const dispatch = useDispatch();
    const salesforceAccountId = useSelector(gvauthSalesfoceAccountId);
    const adminPanelGroups = useSelector(gnviewSelAdminPanelGroups);
    const currentUserEmail = useSelector(gvauthSelEmail);
    const transferUserSuccess = useSelector(gnviewSelTransferUserSuccess);
    const [step, setStep] = useState(1);
    const [users, setUsers] = useState([]);
    const [isUsersLoading, setIsUsersLoading] = useState(true);
    const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
    const [progressNow, setProgressNow] = useState(20);
    const [transferUserEmail, setTransferUserEmail] = useState(null);
    const [transferUserGroupId, setTransferUserGroupId] = useState(null);
    const [transferUserGroup, setTransferUserGroup] = useState(null);

    useEffect(() => {
        dispatch(gnviewGetAdminPanelUsers(salesforceAccountId)).then((response) => {
            setUsers(transformGroups(response.result));
            setIsUsersLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewGetAdminPanelUsers error: ${error.message}`, error));
            setIsUsersLoading(false);
        });
    }, [dispatch, salesforceAccountId]);

    useEffect(() => {
        const groupToUpdate = adminPanelGroups?.find(group => group.id === transferUserGroupId);
        setTransferUserGroup(groupToUpdate);
    }, [adminPanelGroups, transferUserGroupId, setTransferUserGroup]);

    const handleFormSubmit = (values) => {
        setIsFormSubmitLoading(true);
        const updatedUsers = transferUserGroup.users.map(user => {
            if (user.email === transferUserEmail) {
                return {
                    email: values[TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS],
                    first_name: values[TRANSFER_FORM_FIELDS.FIRST_NAME],
                    last_name: values[TRANSFER_FORM_FIELDS.LAST_NAME],
                    role: values[TRANSFER_FORM_FIELDS.ROLE],
                    team: values[TRANSFER_FORM_FIELDS.TEAM],
                    company: user.company
                }
            } else {
                return user;
            }
        });
        const todaysDate = moment().toISOString();
        const updatedGroup = {
            ...transferUserGroup,
            users: [...updatedUsers],
            updated: todaysDate
        };
        dispatch(gnviewUpdateEntitlementGroup(transferUserGroupId, updatedGroup)).then(() => {
            createCognitoUser(values);
        })
            .catch((err) => {
                dispatch(gnviewSetTransferUserSuccess(false));
                dispatch(gnviewSendLogMessage(`gnviewUpdateEntitlementGroup error: ${err.message}`, err));
                setIsFormSubmitLoading(false);
                setStep(3);
            });
    };

    const createCognitoUser = (values) => {
        setProgressNow(50);
        dispatch(gnviewCreateTransferCognitoUser(transferUserGroup.users[0].company, values)).then(() => {
            createSalesforceContact(values);
        })
            .catch((err) => {
                dispatch(gnviewSetTransferUserSuccess(false));
                dispatch(gnviewSendLogMessage(`gnviewCreateTransferCognitoUser error: ${err.message}`, err));
                // If create cognito user fails, update entitlement group back to original user
                dispatch(gnviewUpdateEntitlementGroup(transferUserGroupId, transferUserGroup));
                setIsFormSubmitLoading(false);
                setStep(3);
            })
    };

    const createSalesforceContact = (values) => {
        setProgressNow(90);
        const salesforceBody = {
            companyName: transferUserGroup.users[0].company || '',
            email: values[TRANSFER_FORM_FIELDS.NEW_USERS_EMAIL_ADDRESS],
            firstName: values[TRANSFER_FORM_FIELDS.FIRST_NAME],
            lastName: values[TRANSFER_FORM_FIELDS.LAST_NAME],
            isTransfer: true,
            salesforceAccountId: transferUserGroup.salesforceAccountId
        };
        dispatch(gnviewCreateSalesforceContact(salesforceBody)).then(() => {
            dispatch(gnviewSetTransferUserSuccess(true));
            setIsFormSubmitLoading(false);
            setStep(3);
        })
            .catch((err) => {
                // We set transfer user success to true here because the API will send an email to Natasha if anything goes wrong. No further action required from the user.
                dispatch(gnviewSetTransferUserSuccess(true));
                dispatch(gnviewSendLogMessage(`gnviewCreateSalesforceContact error: ${err.message}`, err));
                setIsFormSubmitLoading(false);
                setStep(3);
            })
    };

    const adminPanelColumns = [...ADMIN_PANEL_COLUMNS];

    const transferUserColumnIdx = adminPanelColumns.findIndex((col) => col.id === 'transferUser');
    if (transferUserColumnIdx !== -1) {
        adminPanelColumns[transferUserColumnIdx] = {
            ...adminPanelColumns[transferUserColumnIdx],
            /* eslint-disable react/prop-types */
            Cell: ({ row, value }) => {
                return value && row?.original?.email !== currentUserEmail ? <Button className='btn-transfer-user' onClick={() => {
                    setTransferUserEmail(row?.original?.email);
                    setTransferUserGroupId(value);
                    setStep(2);
                }}>{ADMIN_PANEL_LABELS.TRANSFER_USER}</Button> : null;
            }
            /* eslint-enable react/prop-types */
        };
    }

    const userNameColumnIdx = adminPanelColumns.findIndex((col) => col.id === 'userName');
    if (userNameColumnIdx !== -1) {
        adminPanelColumns[userNameColumnIdx] = {
            ...adminPanelColumns[userNameColumnIdx],
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => {
                return row?.original?.email ?
                    <>
                        <p>{row?.original?.userName} {(row?.original?.email?.toLowerCase() === currentUserEmail?.toLowerCase()) && <span className='title-role'>Admin</span>}</p>
                        <p className='email-subtitle'>{row?.original?.email}</p>
                    </> : <p>{row?.original?.userName}</p>;
            }
            /* eslint-enable react/prop-types */
        };
    }

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal className={ClassNames('gnview-admin-panel-modal')} show={show} onHide={() => handleModal()}>
                <div className='gnview-admin-panel-main-container'>
                    {step === 1 && (
                        <>
                            <h3 className="admin-panel-title">{ADMIN_PANEL_LABELS.HEADER_TITLE}</h3>
                            <div className='admin-panel-body'>
                                {isUsersLoading && <LoadingSpinner />}
                                {!isUsersLoading ? <GNClientTable
                                    className="users-table"
                                    columns={adminPanelColumns}
                                    data={users}
                                    pagination={true}
                                    noDataMessage={
                                        <p className="no-data-message">{ADMIN_PANEL_LABELS.NO_DATA}</p>
                                    }
                                    settings={{ pageSize: CLIENT_TABLE_PAGE_SIZE, sortBy: [{ id: 'logins', desc: false }] }}
                                /> : <></>}
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            {isFormSubmitLoading ? (
                                <div className="loading-container">
                                    <img src={loadingDoggo} alt='Gracenote Loading Dog Gif' />
                                    <div className="loading-message">{TRANSFER_FORM_LOADING_MESSAGE}</div>
                                    <div className="progress-bar-container">
                                        <ProgressBar animated min={10} max={95} now={progressNow} />
                                    </div>
                                </div>
                            ) : (
                                <TransferForm
                                    handleFormSubmit={handleFormSubmit}
                                    setStep={setStep}
                                    transferUserEmail={transferUserEmail}
                                />
                            )}
                        </>
                    )}
                    {step === 3 && (
                        <TransferThankYou
                            setStep={setStep}
                            transferUserSuccess={transferUserSuccess}
                        />
                    )}
                </div>
            </GNModal>
        </ErrorBoundary>
    )
}

GNAdminPanel.propTypes = {
    show: PropTypes.bool,
    handleModal: PropTypes.func
};

export default GNAdminPanel;