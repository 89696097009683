export const transformGroups = (groups) => {
    const usersList = [];
    groups.forEach(group => {
        if (group.users && group.users.length > 0) {
            group.users.forEach(user => {
                const formattedUser = {
                    id: group.id,
                    groupName: group.name,
                    userName: `${user.first_name || ''} ${user.last_name || ''}`,
                    logins: user.loginsLast30Days || 0,
                    email: user.email
                };
                usersList.push(formattedUser);
            })
        }
    });

    return usersList;
}

export const caseInsensitiveSort = (prev, curr, columnId) => {
    if (prev.original[columnId] && prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
        return 1;
    } else if (prev.original[columnId] && prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
        return -1;
    } else {
        return 0;
    }
};
