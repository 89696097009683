import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from '../../../constants/Languages';
import {
    gvauthGetEntitledProgramLanguages,
    gvauthSelEntitledCountries,
    gnviewSelUserSettings,
    gvauthSelEmail
} from '../../../reducers/GNVAuthReducer';
import GNDropdown from '../../gnDropdown/GNDropdown';
import { Button, Form, Modal } from 'react-bootstrap';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { gnviewUpdateUserSettings } from '../../../actions/GNViewAuthActions';
import {
    GLOBAL_SETTINGS_DESCRIPTION,
    GLOBAL_TIMEZONE_DEFAULT,
    SCHEDULE_GRID_FORMATS,
    GLOBAL_COUNTRY_DEFAULT,
    USER_SETTINGS_BUTTON_TEXT,
    USER_SETTINGS_LABELS,
    USER_SETTINGS_NOTIFICATIONS
} from '../../../constants/AccountSettings';
import uniqBy from 'lodash.uniqby';
import moment from 'moment-timezone';
import { convertCountryCodes } from '../../../utils/GeneralUtils';
import GNNotification from '../../gnNotification/GNNotification';
import PropTypes from 'prop-types';

export const UserSettings = ({setTouched}) => {
    const dispatch = useDispatch();
    const userSettings = useSelector(gnviewSelUserSettings);
    const email = useSelector(gvauthSelEmail);

    // Global Language
    const entitledLanguages = useSelector(gvauthGetEntitledProgramLanguages);
    const globalLanguageOptions = uniqBy(entitledLanguages.map((code) => ({name: LANGUAGES[code], value: code})).filter(item => item.name).sort((a, b) => a.name > b.name ? 1 : -1), 'value');
    // Global Language selected is the one user has saved or default to English
    const defaultLanguage = userSettings?.program_language || (globalLanguageOptions.find((lang) => lang.value === 'en') ? 'en' : globalLanguageOptions[0].value);
    const [globalLanguage, setGlobalLanguage] = useState(defaultLanguage);

    // Global Timezone
    const globalTimezoneOptions = [GLOBAL_TIMEZONE_DEFAULT].concat(moment.tz.names().map((tz) => ({name: `${tz} (${moment().tz(tz).zoneAbbr()})`, value: tz})));
    // Global timezone selected is the one user has saved or default to "Local Station Timezone"
    const defaultTimezone = userSettings?.timezone || GLOBAL_TIMEZONE_DEFAULT.value;
    const [globalTimezone, setGlobalTimezone] = useState(defaultTimezone);

    // Schedule Format
    const defaultScheduleFormat = userSettings?.schedule_grid_start_type || SCHEDULE_GRID_FORMATS.BROADCAST_DAY.value;
    const [scheduleFormat, setScheduleFormat] = useState(defaultScheduleFormat);

    // Global Country
    const entitledCountries = useSelector(gvauthSelEntitledCountries);
    const initialCountry = userSettings?.station_country || GLOBAL_COUNTRY_DEFAULT;
    const [globalCountry, setGlobalCountry] = useState(initialCountry);
    const globalCountryOptions = [GLOBAL_COUNTRY_DEFAULT, ...convertCountryCodes(entitledCountries).map(country => ({ name: country.name, value: country.code }))];

    // Notification
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);

    const handleSaveSettings = () => {
        const body = {
            program_language: globalLanguage,
            schedule_grid_start_type: scheduleFormat?.value || scheduleFormat,
            station_country: globalCountry !== GLOBAL_COUNTRY_DEFAULT ? globalCountry : null,
            timezone: globalTimezone !== GLOBAL_TIMEZONE_DEFAULT.value ? globalTimezone : null
        };
        dispatch(gnviewUpdateUserSettings(email, body)).then(() => {
            setNotification(true, USER_SETTINGS_NOTIFICATIONS.SAVE_SUCCESS, true);
            setTouched(false);
            window.location.reload();
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewUpdateUserSettings error: ${error.message}`, error));
            setNotification(true, USER_SETTINGS_NOTIFICATIONS.SAVE_ERROR, false);
            setTouched(false);
        });
    }

    const setNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    }

    return (
        <div className="user-settings-main-container">
            <div className="user-settings-body">
                <div className="account-settings-modal-header">{USER_SETTINGS_LABELS.GLOBAL_DEFAULT_SETTINGS}</div>
                <span className="user-settings-main-description">
                    {GLOBAL_SETTINGS_DESCRIPTION.MAIN_TEXT}
                </span>
                <div className="user-settings-row">
                    <span className="user-settings-description">
                        {GLOBAL_SETTINGS_DESCRIPTION.TIMEZONE_TEXT_1}
                        <br/>
                        {GLOBAL_SETTINGS_DESCRIPTION.TIMEZONE_TEXT_2}
                    </span>
                </div>
                <div className="user-settings-row">
                    <span className="user-settings-label">{USER_SETTINGS_LABELS.TIMEZONE}</span>
                    {defaultTimezone && <GNDropdown
                        handleChange={(item) => {
                            setTouched(true);
                            setGlobalTimezone(item);
                        }}
                        options={globalTimezoneOptions}
                        value={globalTimezone} />}
                </div>
                <div className="user-settings-row">
                    <span className="user-settings-description">{GLOBAL_SETTINGS_DESCRIPTION.COUNTRY_TEXT}</span>
                </div>
                <div className="user-settings-row">
                    <span className="user-settings-label">{USER_SETTINGS_LABELS.COUNTRY}</span>
                    {initialCountry && <GNDropdown
                        handleChange={(item) => {
                            setTouched(true);
                            setGlobalCountry(item);
                        }}
                        options={globalCountryOptions}
                        value={globalCountry} />}
                </div>
                <div className="user-settings-row">
                    <span className="user-settings-description">{GLOBAL_SETTINGS_DESCRIPTION.LANGUAGE_TEXT}</span>
                </div>
                <div className="user-settings-row">
                    <span className="user-settings-label">{USER_SETTINGS_LABELS.LANGUAGE}</span>
                    {defaultLanguage && <GNDropdown
                        handleChange={(item) => {
                            setTouched(true);
                            setGlobalLanguage(item);
                        }}
                        options={globalLanguageOptions}
                        value={globalLanguage} />}
                </div>
                <div className="account-settings-modal-header">{USER_SETTINGS_LABELS.SCHEDULE_GRID}</div>
                <div className="user-settings-row">
                    <span className="user-settings-label">{USER_SETTINGS_LABELS.SCHEDULE_GRID_START_TIME}</span>
                    {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
                    {Object.values(SCHEDULE_GRID_FORMATS).map((format) =>
                        <Form.Check
                            key={format.value}
                            custom
                            name="schedule-grid"
                            inline
                            id={`custom-radio-schedule-${format.value}`}
                            type='radio'
                            role='radio'
                            label={format.label}
                            onClick={() => {
                                setTouched(true);
                                setScheduleFormat(format);
                            }}
                            defaultChecked={scheduleFormat === format?.value} />
                    )}
                </div>
            </div>
            <Modal.Footer>
                <Button onClick={handleSaveSettings} role='save-button'>{USER_SETTINGS_BUTTON_TEXT.SAVE}</Button>
            </Modal.Footer>
            <GNNotification role='notification' handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={showNotification} success={notificationIsSuccess}/>
        </div>
    )
};

UserSettings.propTypes = {
    setTouched: PropTypes.func
};

export default UserSettings;