import React from 'react';
import { useSelector } from 'react-redux';
import './ThankYou.scss';
import { ROUTES } from '../../config/Routes';
import gnViewLogo from '../../images/GN_View_logo.png';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCopyRightText, LANDING_CONTACT_SALES, LANDING_FOOTER_LINKS } from '../../constants/Landing';
import {
    TRIAL_FORM_THANK_YOU_HEADER,
    TRIAL_FORM_THANK_YOU_MESSAGE,
    TRIAL_FORM_SUBMISSION_ERROR_TEXT,
    TRIAL_FORM_SUBMISSION_ERROR_LINK,
    TRIAL_FORM_THANK_YOU_MESSAGE_SUCCESS_SUPPORT,
    TRIAL_FORM_SUPPORT_EMAIL,
    TRIAL_FORM_THANK_YOU_MESSAGE_ERROR_SUPPORT
} from '../../constants/TrialFormValidation';
import { gnviewSelTrialSignupSuccess } from '../../reducers/GNVAuthReducer';
import corgiErrorPhoto from '../../images/sadcorgi.png';

export const ThankYou = () => {
    const signupSuccess = useSelector(gnviewSelTrialSignupSuccess);

    const handleSupportEmail = (e) => {
        window.location.href = `mailto:${TRIAL_FORM_SUPPORT_EMAIL}`;
        e.preventDefault();
    };

    return (
        <div className="gnview-thank-you-container">
            <div className='thank-you-header-container'>
                <img src={gnViewLogo} className='thank-you-gnlogo' alt='Gracenote View Logo' />
                <div className='buttons-container'>
                    <a href={LANDING_CONTACT_SALES.url} className='contact-sales-button'>
                        <Button variant='bright-blue' className='request-demo-button'>{LANDING_CONTACT_SALES.buttonText}</Button>
                    </a>
                    <Link to={ROUTES.LOGIN} className='login-link'>
                        <Button className='login-button'>Login</Button>
                    </Link>
                </div>
            </div>
            {signupSuccess && (
                <div className="thank-you-content">
                    <div className="thank-you-header">{TRIAL_FORM_THANK_YOU_HEADER}</div>
                    <div className="thank-you-message">{TRIAL_FORM_THANK_YOU_MESSAGE}</div>
                    <div className="thank-you-message-support">
                        {TRIAL_FORM_THANK_YOU_MESSAGE_SUCCESS_SUPPORT}
                        <Link
                            className="trial-form-link"
                            onClick={(e) => handleSupportEmail(e)}
                        >
                            {TRIAL_FORM_SUPPORT_EMAIL}
                        </Link>
                    </div>
                </div>
            )}
            {!signupSuccess && (
                <div className="thank-you-content">
                    <img src={corgiErrorPhoto} />
                    <div className={"thank-you-message-error"}>
                        {TRIAL_FORM_SUBMISSION_ERROR_TEXT}
                        <Link
                            to={ROUTES.TRIAL_FORM}
                            className="trial-form-link"
                        >
                            {TRIAL_FORM_SUBMISSION_ERROR_LINK}
                        </Link>
                    </div>
                    <div className="thank-you-message-support">
                        {TRIAL_FORM_THANK_YOU_MESSAGE_ERROR_SUPPORT}
                    </div>
                    <div className="thank-you-email-support">
                        <Link
                            className="trial-form-link"
                            onClick={(e) => handleSupportEmail(e)}
                        >
                            {TRIAL_FORM_SUPPORT_EMAIL}
                        </Link>
                    </div>
                </div>
            )}
            <div className='footer-content-container'>
                <div className='footer-text'>{getCopyRightText()}</div>
                <div>
                    {LANDING_FOOTER_LINKS.map((footerLink, index) => {
                        return <a href={footerLink.path} className='footer-link' key={index} target="_blank" rel="noreferrer">{footerLink.title}</a>
                    })}
                </div>
            </div>
        </div>
    )
};

export default ThankYou;
