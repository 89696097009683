import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { gvauthSelEmail, gnviewSelAccountModalShow, gnviewSelMenuLastURLVisited, gnviewSelAdminPanelShow, gvauthSelEntitlements } from '../../reducers/GNVAuthReducer';
import { ROUTES } from '../../config/Routes';
import { APP_TYPES, FEATURE_FLAGS } from '../../constants/App';
import GNRadioGroup from '../gnRadioGroup/GNRadioGroup';
import gnLogo from '../../images/gncontentsolutions_logo.png';
import './GradientMenu.scss';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCopyRightText, PRIVACY_POLICY, TERMS_OF_USE_TEXT } from '../../constants/Landing';
import ClassNames from 'classnames';
import { Overlay } from 'react-bootstrap';
import AccountSettingsModal from '../accountSettingsModal/AccountSettingsModal';
import { ACCOUNT_SETTINGS_TAB_OPTIONS, PROFILE_MENU_OPTIONS, CONTACT_SUPPORT } from '../../constants/AccountSettings';
import { gnviewToggleAccountModalShow, gnviewSetAccountModalTab } from '../../actions/GNViewAuthActions';
import { gnviewSetMenuLastURLVisited, gnviewToggleAdminPanelShow } from '../../actions/GNViewActions';
import { GNAdminPanel } from '../gnAdminPanel/GNAdminPanel';

export const GradientMenu = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const defaultAppSelected = window.location.pathname !== ROUTES.GNID_DISTRIBUTION && window.location.pathname !== ROUTES.GNID_DISTRIBUTION_ACCESS_DENIED ? APP_TYPES.GNVIEW : APP_TYPES.GNID;
    const [appType, setAppType] = useState(defaultAppSelected);
    const showAccountModal = useSelector(gnviewSelAccountModalShow);
    const showAdminPanel = useSelector(gnviewSelAdminPanelShow);
    const email = useSelector(gvauthSelEmail);
    const menuLastUrlVisited = useSelector(gnviewSelMenuLastURLVisited);
    const entitlements = useSelector(gvauthSelEntitlements);

    const menuTabs = Object.values(APP_TYPES);
    const onAppTypeSelect = (type) => {
        const typeToSave = type === APP_TYPES.GNID ? APP_TYPES.GNVIEW : APP_TYPES.GNID;
        const currentUrl = () => {
            if (history.location.search?.length > 0) {
                return `${history.location.pathname}${history.location.search}`;
            } else {
                return `${history.location.pathname}`;
            }
        };
        dispatch(gnviewSetMenuLastURLVisited(typeToSave, currentUrl()));
        setAppType(type)
        const defaultUrl = {
            [APP_TYPES.GNID]: ROUTES.GNID_DISTRIBUTION,
            [APP_TYPES.GNVIEW]: ROUTES.SCHEDULES
        }
        const newUrl = menuLastUrlVisited[type] && menuLastUrlVisited[type]?.length > 0 ? menuLastUrlVisited[type] : defaultUrl[type];
        history.push(newUrl);
    };

    const handleAccountModal = useCallback(() => {
        dispatch(gnviewToggleAccountModalShow());
    }, [dispatch]);

    const handleAdminPanel = useCallback(() => {
        dispatch(gnviewToggleAdminPanelShow());
    }, [dispatch]);

    const gnviewSettingsToggle = useCallback(() => {
        dispatch(gnviewSetAccountModalTab(ACCOUNT_SETTINGS_TAB_OPTIONS.GRACENOTE_VIEW_SETTINGS));
        handleAccountModal();
    }, [dispatch, handleAccountModal]);

    useEffect(() => {
        // TODO: Ideally this event string is in a common repo will fix in https://jira.gracenote.com/browse/VIEW-1769
        window.addEventListener('gnview-settings-toggle', gnviewSettingsToggle);
        return () => window.removeEventListener('gnview-settings-toggle', gnviewSettingsToggle);
    }, [gnviewSettingsToggle]);

    useEffect(() => {
        if (!window.location?.pathname?.includes(ROUTES.GNID_DISTRIBUTION) && window.location?.pathname !== ROUTES.GNID_DISTRIBUTION_ACCESS_DENIED) {
            setAppType(APP_TYPES.GNVIEW);
        } else {
            setAppType(APP_TYPES.GNID);
        }
    }, [history.location.pathname]);

    const target = useRef(null);

    const canShowAdminPanel = () => {
        return entitlements?.global?.include?.featureFlags?.includes(FEATURE_FLAGS.ACCOUNT_ADMIN)
    }
    const profileMenu = (
        <div className='profile-dropdown-menu'>
            <div className='email' >{email}</div>
            <div className='profile-dropdown-item' onClick={() => {
                handleAccountModal();
                setShowProfileMenu(false);
            }}>{PROFILE_MENU_OPTIONS.ACCOUNT_SETTINGS}</div>
            {canShowAdminPanel() && <div className='profile-dropdown-item' onClick={() => {
                handleAdminPanel();
                setShowProfileMenu(false);
            }}>{PROFILE_MENU_OPTIONS.ADMIN_PANEL}</div>}
            <div className='profile-dropdown-item' onClick={() => {
                window.location.replace(ROUTES.LOGOUT)
            }}>{PROFILE_MENU_OPTIONS.LOGOUT}</div>
            <div className="profile-menu-footer">
                <div className='privacy-terms-section'>
                    <a href={ROUTES.PRIVACY_POLICY} target="_blank" rel="noreferrer" >{PRIVACY_POLICY.text}</a>
                    <span className="section-spacer">&#8226;</span>
                    <a href={ROUTES.TERMS_OF_USE} target="_blank" rel="noreferrer" >{TERMS_OF_USE_TEXT}</a>
                </div>
                <div className="copyright-text">{getCopyRightText()}</div>
            </div>
        </div>
    );

    return (
        <ErrorBoundary>
            <div className='gnview-gradient'>
                <img src={gnLogo} className='gn-logo' alt='Gracenote'/>
                <GNRadioGroup style="flat" list={menuTabs} onSelect={onAppTypeSelect} default={appType} value={appType} />
                <div className='button-section'>
                    <a href={CONTACT_SUPPORT.url} target='_blank' rel="noreferrer"><FontAwesomeIcon icon='circle-question' className={ClassNames('menu-button-icon')} /></a>
                    <div className='user-profile-section'>
                        <FontAwesomeIcon icon='user-circle' className='menu-button-icon' onClick={() => setShowProfileMenu(prev => !prev)} forwardedRef={target}/>
                        <Overlay rootClose={true} show={showProfileMenu} onHide={() => setShowProfileMenu(false)} placement='bottom' target={target.current}>{profileMenu}</Overlay>
                    </div>
                </div>
                {email && <AccountSettingsModal handleModal={handleAccountModal} show={showAccountModal}/>}
                {showAdminPanel && <GNAdminPanel handleModal={handleAdminPanel} show={showAdminPanel}/>}
            </div>
        </ErrorBoundary>
    )
};

export default GradientMenu;
