export const API_URL = (window.location.port === '8082') ? 'http://127.0.0.1:80/api/' : '/api/';

export const API_ENDPOINTS = {
    ENTITLEMENTS: 'authorizations/entitlements',
    GET_STREAMING_VIDEO_CATALOGS: 'non-live/catalogs',
    USER_SETTINGS: 'user-settings',
    ENTITLEMENT_GROUPS: 'entitlement/groups',
    ENTITLEMENTS_SOURCES: 'gn-ids-entitlements/sources',
    ENTITLEMENTS_EXPRESSION: 'entitlement-expression',
    TRIAL_EMAIL_DOMAINS: 'entitlement/groups/trial-email-domains',
    TRIAL_COMPANIES: 'salesforce/trial-companies',
    SALESFORCE_CONTACT: 'salesforce/contact',
    COGNITO_USER: 'aws/user'
};

export const API_CURRENT_VERSION = '4';
export const API_NEXT_VERSION = '5';
