import React from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import get from 'lodash.get';
import ClassNames from 'classnames';
import "./GNListGroup.scss";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACCOUNT_TYPES } from '../../constants/AccountSettings';

export function renderAccountTypePopover(accountType) {
    return (
        <Popover>
            <Popover.Content>
                <div className='account-type-popover-container'>
                    <div className='popover-title'>Account Types</div>
                    {accountType !== 'Premium' &&
                    <div className='account-type-row'>
                        <div className='account-type-label'>
                            {accountType}
                        </div>
                        <div className='account-type-description'>
                            {ACCOUNT_TYPES[accountType]}
                        </div>
                    </div>
                    }
                    <div className='account-type-row'>
                        <div className='account-type-label'>
                            Premium
                        </div>
                        <div className='account-type-description'>
                            {ACCOUNT_TYPES['Premium']}
                        </div>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    )
}

export const GNListGroup = (props) => {
    return (
        <React.Fragment>
            <div className={'gnlistgroup-wrapper'}>
                <Table size="sm" className={ClassNames('gnlistgroup', props.tableClassName)} role="list-group">
                    <tbody>
                        {props.fields.map((item, index) => {
                            const value = get(props.data, item.prop, '');
                            const extra = Array.isArray(item.extra) ? item.extra.reduce(function(acc, cur) {
                                acc[cur] = get(props.data, cur, '');
                                return acc;
                            }, {}) : {};

                            if (item.render) {
                                return (
                                    <tr key={index}>
                                        <td className="list-label" role="list-label">{item.label}</td>
                                        <td>{value ? item.render(value, extra) : ''}</td>
                                    </tr>
                                )
                            }
                            return (
                                <tr key={index}>
                                    <td className="list-label">
                                        {item.label}
                                        {item.prop === 'accountType' &&
                                            <OverlayTrigger
                                                role='tooltip'
                                                trigger='hover'
                                                overlay={renderAccountTypePopover(value)}
                                                placement='right'
                                            >
                                                <FontAwesomeIcon icon="fa-solid fa-circle-info" className='account-type-tooltip-icon'/>
                                            </OverlayTrigger>
                                        }
                                    </td>
                                    {props.renderListItem && props.sortListItems && <td className='list-item' role='list-item'>{props.sortListItems(value).map((i, idx) => <span key={idx}>{props.renderListItem(i)}</span>)}</td>}
                                    {props.renderListItem && !props.sortListItems && <td className='list-item' role='list-item'>{Array.isArray(value) ? value.map((i, idx) => <span key={idx}>{props.renderListItem(i)}</span>) : value}</td>}
                                    {!props.renderListItem && <td>{Array.isArray(value) ? value.join(', ') : value}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
};

GNListGroup.propTypes = {
    fields: PropTypes.array,
    data: PropTypes.object,
    tableClassName: PropTypes.object,
    renderListItem: PropTypes.func,
    sortListItems: PropTypes.func
};

export default GNListGroup;
