export const ROUTES = {
    COGNITO: "/start",

    LANDING: "/",
    LOGIN: "/login",
    LOGOUT: "/logout",
    AUTH_FORBIDDEN: "/auth-forbidden",
    CONTACT_US: "/contact-us",
    NOT_FOUND: "/not-found",
    TERMS_OF_USE: "/terms-of-use",

    // Details
    PROGRAM_DETAILS: "/programs",

    // Reports
    SCHEDULES: "/schedules",

    // GNIDS
    GNID_DISTRIBUTION: "/gnid-distribution",
    GNID_DISTRIBUTION_ACCESS_DENIED: "/gnid-distribution-access-denied",

    // Support
    REPORT_AN_ISSUE: "/report-an-issue",

    TRIAL_FORM: "/trial-form",
    THANK_YOU: "/thank-you",
    PRIVACY_POLICY: "/privacy-policy"
};
