import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { ROUTES } from '../../config/Routes';
import Cookies from 'js-cookie';
import './Forbidden.scss';
import { gnviewClearState } from '../../actions/GNViewAuthActions';
import PropTypes from "prop-types";
import { GNVIEW_EMAIL, GNVIEW_VERSION } from '../../constants/LocalStorage';

export class Forbidden extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        // Clear all cookies and local storage. Force a login again
        Cookies.remove(GNVIEW_EMAIL);
        Cookies.remove(GNVIEW_VERSION);
        localStorage.clear();
        this.props.gnviewClearState();
    }

    render() {
        return (
            <div className='gnview-forbidden-content'>
                <div className='forbidden-content-body'>
                    <span>ACCESS FORBIDDEN</span>
                    <span>Your account has been used to log in from other devices, or your account is missing sufficient permissions.</span>
                    <span>Please try logging in again.</span>
                    <Link to={ROUTES.LANDING}>
                        <Button className='forbidden-button'>Back to Home</Button>
                    </Link>
                </div>
            </div>
        )
    }
}

Forbidden.propTypes = {
    gnviewClearState: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ gnviewClearState }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Forbidden);
